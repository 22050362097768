import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import CampaignDiscover from "../../components/explore/discover";
import GeneralHeader from "../../components/general/header";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Explore = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const headerImage = useAsync(data.headerImage, []);

  // const filters = [
  //   {
  //     id: "all",
  //     title: t("All actions"),
  //     orderBy: ["popularity", "desc"],
  //   },
  //   ...(clubs || []).map((club) => ({
  //     id: club.id,
  //     title: club.title,
  //     club,
  //     orderBy: ["popularity", "desc"],
  //   })),
  // ] as Filter[];

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("Discover support actions")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="explore-all">
          <GeneralHeader
            title={t("Discover")}
            image={headerImage.result}
            showAll={false}
          />
          <CampaignDiscover />
        </div>
      </main>
    </ScrollUp>
  );
};

export default observer(Explore);
