import { useTranslation } from "react-i18next";

import IdealLogo from "../../assets/images/ideal-logo.svg";
import MastercardLogo from "../../assets/images/mastercard-logo.svg";
import VisaLogo from "../../assets/images/visa-logo.svg";

interface Props {}
const Disclaimer = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="cart-totals border border-primary">
        <p className="disclaimer">
          {t(
            "You will get your money back if the club doesn't cash in the donations",
          )}
        </p>
      </div>
      <div className="cart-totals cart-logos">
        <div className="logo">
          <img src={IdealLogo} alt="iDEAL" />
        </div>
        <div className="logo">
          <img src={MastercardLogo} alt="Mastercard" />
        </div>
        <div className="logo">
          <img src={VisaLogo} alt="Visa" />
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
