import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaFacebook, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { Campaign } from "../../store";

interface Props {
  campaign: Campaign;
  show?: boolean;
  onHide?: () => void;
}
const Share = (props: Props) => {
  const { t } = useTranslation();
  const location = window.location;
  const { show, onHide, campaign } = props;
  const shareUrl = encodeURIComponent(location.href);
  const shareText = t("You really have to see this") + ": " + shareUrl;

  const openShareWindow = (target: string) => {
    window.open(
      target,
      "targetWindow",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width=600",
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Mobilize your fellow supporters")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col className="share-col">
              <div className="share-text">{t("Share your support action")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="share-col">
              <button
                className="share-icon"
                onClick={() =>
                  openShareWindow(
                    `mailto:?subject=${campaign.club?.title} ${t("support action")}: ${
                      campaign.title
                    }&body=${shareText}`,
                  )
                }
              >
                <FaEnvelope />
              </button>
            </Col>
            <Col className="share-col">
              <button
                className="share-icon"
                onClick={() =>
                  openShareWindow(
                    "https://api.whatsapp.com/send?text=" + shareText,
                  )
                }
              >
                <FaWhatsapp />
              </button>
            </Col>
            <Col className="share-col">
              <button
                className="share-icon"
                onClick={() =>
                  openShareWindow(
                    "https://www.facebook.com/sharer/sharer.php?u=" + shareUrl,
                  )
                }
              >
                <FaFacebook />
              </button>
            </Col>
            <Col className="share-col">
              <button
                className="share-icon"
                onClick={() =>
                  openShareWindow(
                    "https://twitter.com/intent/tweet?text=" + shareText,
                  )
                }
              >
                <FaXTwitter />
              </button>
            </Col>
            <Col className="share-col">
              <button
                className="share-icon"
                onClick={() =>
                  openShareWindow(
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                      shareUrl,
                  )
                }
              >
                <FaLinkedin />
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Share;
