import React, { useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useStore } from "../../store";

const Settings = () => {
  const { t } = useTranslation();
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const { auth } = useStore();

  const openDeleteModal = () => setDeleteModalShown(true);
  const closeDeleteModal = () => setDeleteModalShown(false);

  const deleteAccount = () => {
    auth.user?.deleteAccount();
  };

  const renderDeleteSetting = () => {
    return (
      <tr>
        <td>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="title">{t("Delete my account")}</span>
              <br />
              <span className="description text-muted">
                {t("This is permanent and irreversible.")}
              </span>
            </div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={openDeleteModal}
            >
              {t("Delete")}
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal show={deleteModalShown} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Are you sure?")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t("This will permanently remove your account from our servers.")}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeDeleteModal}>
            {t("Cancel")}
          </Button>
          <Button onClick={deleteAccount} variant="primary">
            {t("Yes, delete my account")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="settings-table settings">
      <Table>
        <tbody>{renderDeleteSetting()}</tbody>
        {renderDeleteModal()}
      </Table>
    </div>
  );
};

export default Settings;
