import first from "lodash/first";
import last from "lodash/last";
import split from "lodash/split";
import trimStart from "lodash/trimStart";

export const getQuery = (search: string, key: string) => {
  const query = split(trimStart(search, "?"), "&").reduce(
    (acc, object) => {
      const pair = split(object, "=");
      if (pair.length === 2) {
        acc[first(pair) || ""] = last(pair);
      }
      return acc;
    },
    {} as { [key: string]: string | undefined },
  );
  return query[key];
};
