import firebase from "firebase/compat/app";

import { Data } from "../general/data";
import Document from "../general/document";

export type ProfileVerified = "club" | "supporters";

export interface ProviderData {
  providerId: "password" | "facebook.com" | "twitter.com" | "google.com";
  uid: string;
}

export interface NotificationData {
  [key: string]: {
    dateChanged: Date;
    accepted: boolean;
  };
}

export interface UserAgreementData {
  dateAccepted: Date;
}

export interface UserSegmentData {
  channel?: any[];
  frequency?: string;
}

interface ProfileData extends Data {
  displayName: string;
  email?: string;
  photoURL?: string;
  verified?: ProfileVerified;
  providerData?: ProviderData;
  language?: string;
  notifications?: NotificationData;
  userAgreement: UserAgreementData;
  segment: UserSegmentData;
}

type ProfileUpdateData = Partial<
  Omit<Omit<ProfileData, "createdAt">, "updatedAt">
>;

interface PhotoURLUpdateData {
  upload?: Blob;
}

class Profile extends Document<ProfileData> {
  get uid() {
    return this.id || "";
  }
  get createdAt() {
    return this.data.createdAt?.toDate();
  }
  get displayName() {
    return this.data.displayName;
  }
  get email() {
    return this.data.email;
  }
  get notificationPreferences() {
    return this.data.notifications;
  }
  get userAgreement() {
    return this.data.userAgreement;
  }
  get photoURL() {
    return this.data.photoURL;
  }
  get isVerified() {
    return !!this.data.verified;
  }
  get verified() {
    return this.data.verified;
  }
  get providerData() {
    return this.data.providerData;
  }
  get language() {
    return this.data.language;
  }

  update = async (data: ProfileUpdateData) => {
    await super.set(
      {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
  };

  updatePhotoURL = async (data: PhotoURLUpdateData) => {
    const { upload } = data;
    const photoURL =
      upload &&
      (await firebase
        .storage()
        .ref()
        .child(`/profile/${this.id}`)
        .put(upload)
        .then((snapshot) => snapshot.ref.getDownloadURL()));

    await super.set(
      {
        photoURL: photoURL || firebase.firestore.FieldValue.delete(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
  };

  providerAccount = async (data: ProviderData) => {
    try {
      const result = await firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("providerAccount")(data);
      return result.data;
    } catch (error) {
      console.error("Profile url failed: ", error);
    }
  };
}

export default Profile;
