import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import AdminClubForm from "../../../components/admin/league/form";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const Form = () => {
  const { leagueId } = useParams();
  const { data } = useStore();
  const { country } = data;
  const league = useMemo(
    () => (leagueId ? country?.league(leagueId) : undefined),
    [leagueId, country],
  );

  if (league && !league.isLoaded) return <Preloader />;
  return (
    <div className="admin">
      <AdminClubForm league={league} />
    </div>
  );
};

export default observer(Form);
