import firebase from "firebase/compat/app";
import { Collection } from "firestorter";
import { TFunction } from "i18next";
import numeral from "numeral";
import { v4 as uuid } from "uuid";

import Campaign from "./campaign";
import Club from "./club";
import League, { LeagueData } from "./league";
import { Data } from "../general/data";
import Document from "../general/document";

type CountryFeeType = "percentage" | "amount";

export interface CountryFeeData {
  type: CountryFeeType;
  value: number;
}

export interface CountryFee {
  default: CountryFeeData;
  min?: CountryFeeData;
  max?: CountryFeeData;
  tax?: number;
  add?: CountryFeeData;
}

export interface CountryFund {
  min?: number;
  max?: number;
  tax?: number;
  add?: number;
}

export interface Contacts {
  value: string;
  email: string;
}

export interface CountryData extends Data {
  title: string;
  fee: CountryFee;
  currency: string;
  fund?: CountryFund;
  contacts?: Contacts[];
}

interface LeagueAddData
  extends Omit<Omit<LeagueData, "createdAt">, "updatedAt"> {
  upload?: Blob;
  id?: string;
}

interface PaymentAmount {
  currency: string;
  value: string;
}

interface PaymentImage {
  size1x: string;
  size2x: string;
  svg: string;
}

interface PaymentIssuer {
  resource: string;
  id: string;
  name: string;
  image: PaymentImage;
}

export interface PaymentMethod {
  id: "ideal" | "creditcard";
  description: string;
  minimumAmount: PaymentAmount;
  maximumAmount?: PaymentAmount;
  image: PaymentImage;
  issuers?: PaymentIssuer[];
}

class Country extends Document<CountryData> {
  private _leagues?: Collection<League>;
  private _campaigns?: Collection<Campaign>;

  get title() {
    return this.data.title;
  }

  get leagues() {
    if (!this._leagues) {
      this._leagues = new Collection(() => `${this.path}/league`, {
        query: (ref: firebase.firestore.CollectionReference) =>
          ref.orderBy("title"),
        createDocument: (source, options) =>
          new League(source, options, this.store),
      });
    }
    return this._leagues;
  }

  fetchAllCampaigns = async (query: any, filter: any) => {
    const arrayOfCollection: any = [];
    const COLLECTIONS = this.filteredCampaigns(query, filter);
    await COLLECTIONS.then((data) => arrayOfCollection.push(data));
    return arrayOfCollection;
  };

  filteredCampaigns = async (query: any, filter: any) => {
    const { where, limit } = query || {};
    const pathWithFilter = `${this.path}/${filter}`;
    return new Collection(() => pathWithFilter, {
      createDocument: (source, options) =>
        new Campaign(source, options, this.store),
      query: (ref: firebase.firestore.CollectionReference) => {
        const querySnapshot = firebase.firestore().collectionGroup("campaign");
        let newRef: firebase.firestore.Query<firebase.firestore.DocumentData> =
          querySnapshot.where("published", "==", true);

        if (filter?.league?.id) {
          newRef = newRef.where("leagueId", "==", filter?.league?.id);
          if (filter?.league?.id !== "aOauKTjqnl94q7fubLY0") {
            if (filter?.club?.id) {
              newRef = newRef.where("clubId", "==", filter?.club?.id);
            }
          }
        }
        if (where) {
          newRef = newRef.where(where[0], where[1], where[2]);
        }
        if (limit) {
          newRef = newRef.limit(limit);
        }
        return newRef;
      },
    });
  };

  get applicationFee() {
    return this.data.fee;
  }

  displayTax(t: TFunction) {
    const fee = this.data.fee;
    return t(
      "Clubmeister charges {{percentage}}% to cover tax administration.",
      { percentage: fee.tax && fee.tax * 100 },
    );
  }

  displayApplicationFee(t: TFunction) {
    const fee = this.data.fee;

    const displayFee = (fee: CountryFeeData) => {
      if (fee.type === "percentage")
        return t("{{percentage}}% of the total amount", {
          percentage: fee.value,
        });
      else return numeral(fee.value / 100).format("0,0.00");
    };

    return [displayFee(fee.default)];
  }

  get currency() {
    return this.data.currency;
  }

  get displayCurrencyPrefix() {
    switch (this.currency) {
      case "eur":
        return "€";
    }
    return "";
  }

  get minFund() {
    return this.data.fund?.min || 0;
  }

  get addFund() {
    return this.data.fund?.add || 0;
  }

  get maxFund() {
    return this.data.fund?.max;
  }

  get fundTax() {
    return this.data.fund?.tax;
  }

  league = (leagueId: string) => {
    if (!this.id) return undefined;
    return new League(`${this.path}/league/${leagueId}`, {}, this.store);
  };

  club = (leagueId: string, clubId: string) => {
    if (!this.id) return undefined;
    return new Club(
      `${this.path}/league/${leagueId}/club/${clubId}`,
      {},
      this.store,
    );
  };

  addLeague = async (data: LeagueAddData) => {
    const { upload, id, ...otherData } = data;
    const logo =
      (upload &&
        (await firebase
          .storage()
          .ref()
          .child(`${this.path}/league/${uuid()}`)
          .put(upload)
          .then((snapshot) => snapshot.ref.getDownloadURL()))) ||
      data.logo;
    if (id) {
      await firebase
        .firestore()
        .collection(`${this.path}/league`)
        .doc(id)
        .set(
          {
            ...otherData,
            logo,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
    } else {
      await firebase
        .firestore()
        .collection(`${this.path}/league`)
        .add({
          ...otherData,
          logo,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  paymentMethods = async (): Promise<PaymentMethod[]> => {
    const { data } = await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("paymentMethods")({ countryId: this.id });
    return data as PaymentMethod[];
  };
}

export default Country;
