import { observer } from "mobx-react-lite";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useAsync } from "react-async-hook";
import { Table, Row, Col, Badge } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStore, Fund } from "../../store";

const Donations = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const funds = useAsync(async () => auth.user?.funds(), [auth.user]);

  const renderFund = (fund: Fund) => {
    const { id, campaign, amount, createdAt, refundStatus } = fund;
    if (!campaign) return;

    const currencyPrefix =
      campaign.club?.league?.country?.displayCurrencyPrefix;

    const refund =
      refundStatus === "succeeded" ? (
        <Badge variant="primary" className="float-right">
          <Trans>Refunded</Trans>
        </Badge>
      ) : refundStatus === "pending" ? (
        <Badge variant="warning" className="float-right">
          <Trans>Refund pending</Trans>
        </Badge>
      ) : refundStatus === "failed" ? (
        <Badge variant="danger" className="float-right">
          <Trans>Refund failed</Trans>
        </Badge>
      ) : undefined;
    return (
      <tr key={id}>
        <td>
          <Row>
            <Col md={6}>
              {campaign?.title} {refund}
            </Col>
            <Col md={2}>
              {currencyPrefix} {numeral(amount / 100).format("0,0.00")}
            </Col>
            <Col md={2}>{createdAt ? moment(createdAt).fromNow() : ""}</Col>
            <Col md={2}>
              <Link to={campaign.link} className="active">
                {t("Check out support action")}
              </Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  const renderEmpty = () => {
    return (
      <tr>
        <td className="empty">{t("No donations found")}</td>
      </tr>
    );
  };
  return (
    <Table borderless={!funds?.result?.length}>
      <tbody>
        {funds?.result?.length ? funds.result.map(renderFund) : renderEmpty()}
      </tbody>
    </Table>
  );
};

export default observer(Donations);
