import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  action?: string;
}
const Consent = (props: Props) => {
  const { action } = props;
  return (
    <p className="consent">
      <Trans>
        {{ action }}, do you accept our{" "}
        <Link to="privacy">Privacy statement</Link> and{" "}
        <Link to="terms">Terms and conditions</Link>.
      </Trans>
    </p>
  );
};

export default Consent;
