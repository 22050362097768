import Campaign from "./campaign";
import Profile from "../auth/profile";
import { Data } from "../general/data";
import Document from "../general/document";

export interface CommentData extends Data {
  text: string;
  userId: string;
  blocked: boolean;
}

class Comment extends Document<CommentData> {
  private _user?: Profile;
  private _campaign?: Campaign;

  get text() {
    return this.data.text;
  }

  get blocked() {
    return this.data.blocked;
  }

  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  get user() {
    if (this.data.userId && this.data.userId !== this._user?.id) {
      this._user = new Profile(`profile/${this.data.userId}`, {}, this.store);
    }
    return this._user;
  }

  get campaign() {
    if (!this._campaign && this.ref?.parent?.parent) {
      this._campaign = new Campaign(this.ref?.parent?.parent, {}, this.store);
    }
    return this._campaign;
  }
}

export default Comment;
