import { Document } from "firestorter";
import { DocumentSource, IDocumentOptions } from "firestorter/lib/Types";

import Store from "..";

class StoreDocument<T extends object> extends Document<T> {
  readonly store: Store;

  constructor(source: DocumentSource, options: IDocumentOptions, store: Store) {
    super(source, options);
    this.store = store;
  }
}

export default StoreDocument;
