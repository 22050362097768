import { observer } from "mobx-react-lite";
import numeral from "numeral";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

import Config from "../../config.json";
import { CustomError, localizedError } from "../../helpers/error";
import { useStore, Fund, PaymentMethod } from "../../store";
import { PaymentData } from "../../store/data/fund";
import Preloader from "../general/preloader";

interface Props {
  fund: Fund;
}

const Payment = (props: Props) => {
  const { fund } = props;
  const { amount } = fund;
  const { data, i18n } = useStore();
  const { locale } = i18n;
  const navigate = useNavigate();
  const [payment, setPayment] = useState<Partial<PaymentData>>({
    method: "ideal",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const location = window.location;
  // const stripe = useStripe();
  // const elements = useElements();
  const currencyPrefix =
    fund.campaign?.club?.league?.country?.displayCurrencyPrefix;
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const methods = useAsync(
    async () => await data.country?.paymentMethods(),
    [],
  );

  const mollie = useMemo(() => {
    return Mollie
      ? Mollie(Config.mollie.profileId, {
          locale,
          // @ts-ignore FIXME Error on production
          testmode: !!Config.beta,
        })
      : undefined;
  }, [Mollie, locale]);

  useEffect(() => {
    if (
      !methods.loading &&
      !!methods.result?.find((method) => method.id === "creditcard")
    ) {
      const options = {
        styles: {
          base: {
            display: "block",
            width: "100%",
            height: "calc(1.5em + 0.75rem + 2px)",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            color: "#495057",
            backgroundColor: "#ff0000",
            backgroundClip: "padding-box",
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            transition: "border-color .15s",
          },
        },
      };
      const cardNumber = mollie.createComponent("cardNumber", options);
      cardNumber.mount("#card-number");

      const cardNumberError = document.querySelector("#card-number-error");
      cardNumberError &&
        cardNumber.addEventListener("change", (event: any) => {
          if (event.error && event.touched) {
            cardNumberError.textContent = event.error;
          } else {
            cardNumberError.textContent = "";
          }
        });

      const cardHolder = mollie.createComponent("cardHolder", options);
      cardHolder.mount("#card-holder");

      const cardHolderError = document.querySelector("#card-holder-error");
      cardHolderError &&
        cardHolder.addEventListener("change", (event: any) => {
          if (event.error && event.touched) {
            cardHolderError.textContent = event.error;
          } else {
            cardHolderError.textContent = "";
          }
        });

      const expiryDate = mollie.createComponent("expiryDate", options);
      expiryDate.mount("#expiry-date");

      const expiryDateError = document.querySelector("#expiry-date-error");
      expiryDateError &&
        expiryDate.addEventListener("change", (event: any) => {
          if (event.error && event.touched) {
            expiryDateError.textContent = event.error;
          } else {
            expiryDateError.textContent = "";
          }
        });

      const verificationCode = mollie.createComponent(
        "verificationCode",
        options,
      );
      verificationCode.mount("#verification-code");

      const verificationCodeError = document.querySelector(
        "#verification-code-error",
      );
      verificationCodeError &&
        verificationCode.addEventListener("change", (event: any) => {
          if (event.error && event.touched) {
            verificationCodeError.textContent = event.error;
          } else {
            verificationCodeError.textContent = "";
          }
        });
    }
  }, [mollie, methods.loading, methods.result]);

  const pay = async () => {
    try {
      setLoading(true);
      if (payment?.method === "ideal" && !payment.issuer) {
        throw new CustomError(
          "Ideal bank empty",
          "payment/method-ideal-no-issuer",
        );
      } else if (payment?.method === "creditcard" && !payment.cardToken) {
        throw new CustomError("Creditcard empty", "payment/method-card-empty");
      }

      const response = await fund.createPayment({
        ...payment,
        redirectUrl: `${location.href}/completed`,
      } as PaymentData);

      const checkout = response?.data?.checkout;

      if (checkout) {
        window.location.replace(checkout?.href);
      } else {
        navigate(`${fund.link}/completed`, {
          state: { status: response?.data?.status },
        });
      }
      setLoading(false);
    } catch (err) {
      setError(localizedError(err as Error, t));
      setLoading(false);
    }
  };

  const submit = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderPaymentIDeal = (method: PaymentMethod) => {
    // if (type === 'ideal')
    return (
      <Form.Control
        as="select"
        onChange={(e) =>
          setPayment({ ...payment, method: "ideal", issuer: e.target.value })
        }
      >
        <option value="">{t("Select your bank")}</option>
        {method.issuers?.map((issuer) => (
          <option value={issuer.id} key={issuer.id}>
            {issuer.name}
          </option>
        ))}
      </Form.Control>
    );
  };

  const renderPaymentCreditCard = (method: PaymentMethod) => {
    // if (type === 'card')

    return (
      <Card>
        <Card.Body>
          <div id="card-number"></div>
          <div id="card-number-error"></div>

          <div id="card-holder"></div>
          <div id="card-holder-error"></div>

          <div id="expiry-date"></div>
          <div id="expiry-date-error"></div>

          <div id="verification-code"></div>
          <div id="verification-code-error"></div>
        </Card.Body>
      </Card>
    );
  };

  const renderPaymentElements = (method: PaymentMethod) => {
    const { id } = method;
    switch (id) {
      case "ideal":
        return renderPaymentIDeal(method);
      case "creditcard":
        return renderPaymentCreditCard(method);
    }
  };

  const renderPaymentMethod = (method: PaymentMethod) => {
    const { id, image, description } = method;
    return (
      <Card.Header className="payment-method" key={id}>
        <Form.Check
          type="radio"
          checked={payment?.method === id}
          onChange={() => setPayment({ method: id })}
          label={
            <div>
              <img alt={description} src={image.svg} />
              {description}
            </div>
          }
        />
        <Accordion.Collapse eventKey={id}>
          <Card.Body>{renderPaymentElements(method)}</Card.Body>
        </Accordion.Collapse>
      </Card.Header>
    );
  };

  if (methods.loading) return <Preloader />;

  return (
    <form onSubmit={submit}>
      <Accordion activeKey={payment?.method}>
        {methods.result?.map(renderPaymentMethod)}
      </Accordion>
      <Form.Check
        onChange={() => setDisabled(!disabled)}
        className="consent"
        defaultChecked={false}
        id="agreement"
        type="checkbox"
        label={
          <span>
            <Trans i18nKey="donation_agreement_checkbox">
              Ik ben 18 jaar of ouder en ga akkoord met de{" "}
              <Link
                className="d-inline"
                to={`/${data.country?.id}/policy/terms`}
              >
                Algemene voorwaarden
              </Link>{" "}
              en het{" "}
              <Link
                className="d-inline"
                to={`/${data.country?.id}/policy/privacy`}
              >
                Privacy beleid
              </Link>{" "}
              van Clubmeister
            </Trans>
          </span>
        }
      />
      {error && (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <Button
        variant="primary"
        disabled={loading || disabled}
        type="button"
        onClick={pay}
        block={!isDesktop}
        className="pay-button"
      >
        {loading ? (
          <span>{t("Upload...")}</span>
        ) : (
          <span>
            {t("Pay")} {currencyPrefix}{" "}
            {numeral(amount / 100).format("0,0[.]00")}
          </span>
        )}
      </Button>
    </form>
  );
};

export default observer(Payment);
