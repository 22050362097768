// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactDOM from "react-dom";

import "wowjs/css/libs/animate.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bxslider/dist/jquery.bxslider.min.css";
import "magicsuggest/magicsuggest-min.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "react-datetime/css/react-datetime.css";

import "./styles/style.css";
import "./styles/responsive.css";

// eslint-disable-next-line no-unused-vars
import App from "./app";
import registerServiceWorker from "./helpers/serviceworker";

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
