import classNames from "classnames";
import React, {
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  ChangeEvent,
} from "react";

interface Props
  extends Omit<
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "onChange"
  > {
  invalid?: boolean;
  onChange?: (value: string) => void;
}
const Textarea = (props: Props) => {
  const { invalid, onChange, className, ...otherProps } = props;
  const textareaClass = classNames(
    "form-control",
    {
      "is-invalid": invalid,
    },
    className,
  );

  const update = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <textarea className={textareaClass} onChange={update} {...otherProps} />
  );
};

export default Textarea;
