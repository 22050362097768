import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useTranslation } from "react-i18next";
import LinesEllipsis from "react-lines-ellipsis";

import { Campaign } from "../../store";
import LinkWrapper from "../general/link";

interface Props {
  campaign: Campaign;
  newTab: boolean;
}

const CampaignItem = (props: Props) => {
  const { campaign, newTab } = props;
  const {
    title,
    description,
    createdAt,
    fundings,
    raised,
    goal,
    club,
    image,
    // likes,
  } = campaign;

  const { t } = useTranslation();
  const classes = classNames("col-lg-4", "col-md-6");
  const progress = goal ? raised / goal : 0;
  const currencyPrefix = campaign.club?.league?.country?.displayCurrencyPrefix;

  // const { auth } = useStore();
  // const [didLike, setDidLike] = useState(campaign.didLike);

  // useEffect(() => {
  //   if (campaign.didLike !== didLike) {
  //     setDidLike(didLike);
  //   }
  // }, [campaign.didLike]);

  // const like = async () => {
  //   try {
  //     if (!auth.isSignedIn) {
  //       await auth.signInAnonymously();
  //     }
  //     setDidLike(!didLike);
  //     await campaign.addLike();
  //   } catch (error) {
  //     const { message } = error as Error;
  //     console.error("Like error: ", message);
  //   }
  // };

  // const renderLike = () => {
  //   const popover = (
  //     <Popover id="popover-basic">
  //       <Popover.Content>
  //         {didLike ? t("You liked this idea") : t("I like this idea")}
  //       </Popover.Content>
  //     </Popover>
  //   );
  //   return (
  //     <OverlayTrigger
  //       trigger={["hover", "focus"]}
  //       overlay={popover}
  //       rootClose
  //       delay={{ show: 300, hide: 0 }}
  //     >
  //       <button className={likeClasses} onClick={like}>
  //         <i className="far fa-thumbs-up" />
  //       </button>
  //     </OverlayTrigger>
  //   );
  // };

  // const likeClasses = classNames(
  //   "btn",
  //   { "btn-secondary": !didLike, "btn-primary": didLike },
  //   "campaign-like"
  // );

  return (
    <div className={classes}>
      <div className="campaign-item">
        <LinkWrapper newTab={newTab} className="overlay" to={campaign?.link}>
          {image && <img src={image} alt={t("Action")} />}
          <i className="far fa-eye"></i>
        </LinkWrapper>
        <div className="campaign-box">
          <LinkWrapper
            newTab={newTab}
            to={`${club?.link}/explore`}
            className="category"
          >
            {<img className="logo" src={club?.logo} alt={t("Club")} />}{" "}
            {club?.title}
          </LinkWrapper>
          <div className="process-time">
            <i className="far fa-clock" aria-hidden="true" />
            <span>{createdAt ? moment(createdAt).fromNow() : ""}</span>
          </div>
          <div className="campaign-info">
            <h3>
              <LinkWrapper newTab={newTab} to={campaign?.link}>
                <LinesEllipsis text={title} maxLine="2" />
              </LinkWrapper>
            </h3>
            <LinesEllipsis text={description} maxLine="3" />
          </div>
          <div className="process">
            <div className="process-info row">
              <div className="col-lg-6">
                <span>
                  {currencyPrefix} {numeral(raised / 100).format("0,0[.]00")}
                </span>{" "}
                {t("donated")}
              </div>
              <div className="col-lg-6">
                <span>
                  {currencyPrefix} {numeral(goal / 100).format("0,0[.]00")}
                </span>{" "}
                {t("target amount")}
              </div>
            </div>
            <div className="raised">
              <span style={{ width: `${progress * 100}%` }} />
            </div>
            <div>
              <div className="process-fundings">
                <span>{numeral(fundings).format("0,0")}</span>{" "}
                {t("donations", { count: fundings })}
              </div>
              {/* <div className="process-likes">
                <span>{numeral(likes).format("0,0")}</span>{" "}
                {t("like", { count: likes })}
              </div> */}
            </div>
          </div>
          <div className="campaign-actions">
            <LinkWrapper
              newTab={newTab}
              to={campaign.link}
              className="btn btn-primary"
            >
              {t("Check out")}
            </LinkWrapper>
            {/* {renderLike()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CampaignItem);
