import React from "react";
import { resetCookieConsentValue } from "react-cookie-consent";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { COOKIE_CONSENT } from "./cookies";
import LanguageSelector from "./languages";

interface Props {}
const Footer = (props: Props) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer id="footer" className="site-footer">
      <div className="footer-menu">
        <div className="container">
          <div className="d-flex justify-content-center py-3">
            <div className="follow">
              <ul>
                <li className="facebook">
                  <a
                    rel="noopener noreferrer"
                    target="_Blank"
                    href="https://www.facebook.com/Clubmeister1"
                  >
                    <i className="fab fa-facebook-f" aria-hidden="true" />
                  </a>
                </li>
                <li className="twitter">
                  <a
                    rel="noopener noreferrer"
                    target="_Blank"
                    href="https://twitter.com/meister_club"
                  >
                    <i className="fa-brands fa-x-twitter" aria-hidden="true" />
                  </a>
                </li>
                <li className="instagram">
                  <a
                    rel="noopener noreferrer"
                    target="_Blank"
                    href="https://www.instagram.com/club.meister/"
                  >
                    <i className="fab fa-instagram" aria-hidden="true" />
                  </a>
                </li>
                <li className="youtube">
                  <a
                    rel="noopener noreferrer"
                    target="_Blank"
                    href="https://www.youtube.com/channel/UCYaI0_VgdNZG2hgivi5kxOg"
                  >
                    <i className="fab fa-youtube" aria-hidden="true" />
                  </a>
                </li>
                <li className="linkedin">
                  <a
                    rel="noopener noreferrer"
                    target="_Blank"
                    href="https://www.linkedin.com/company/71121813"
                  >
                    <i className="fab fa-linkedin-in" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div className="col-lg-6 col-9 text-center">
              <div className="footer-menu-item">
                <ul className="d-flex justify-content-center flex-row flex-wrap">
                  <li>
                    <Link to=".">{t("menu.home")}</Link>
                  </li>
                  <li>
                    <Link to="info">{t("menu.howItWorks")}</Link>
                  </li>
                  <li>
                    <Link to="form">{t("menu.startAction")}</Link>
                  </li>
                  <li>
                    <Link to="explore">{t("menu.discover")}</Link>
                  </li>
                  <li>
                    <Link to="about">{t("menu.about")}</Link>
                  </li>
                  <li>
                    <Link to="faq">{t("menu.faq")}</Link>
                  </li>
                  <li>
                    <Link to="contact">{t("menu.contact")}</Link>
                  </li>
                  <li>
                    <Link to="clubs">{t("menu.forClubs")}</Link>
                  </li>
                  <li>
                    <Link to="policy/terms">{t("menu.termsConditions")}</Link>
                  </li>
                  <li>
                    <Link to="policy/privacy">{t("menu.privacyPolicy")}</Link>
                  </li>
                  <li>
                    <Link to="policy/cookies">{t("menu.cookiePolicy")}</Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      onClick={() => resetCookieConsentValue(COOKIE_CONSENT)}
                    >
                      {t("Change cookie settings")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="my-4 text-muted">
                <p>Clubmeister B.V KvK: 82542902</p>
                <p>
                  Beheerstichting Supportersgelden Clubmeister KvK: 83136495
                </p>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="footer-top-right">
              <LanguageSelector variant="light" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <p className="copyright">
            <Trans i18nKey="copyrightText">
              {{ year }} by Clubmeister. All rights reserved.
            </Trans>
          </p>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={"#"}
            className="back-top"
            onClick={() =>
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            {t("Scroll up")}
            <i className="fas fa-angle-up"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
