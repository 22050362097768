import React from "react";
import { useTranslation } from "react-i18next";

const Sent = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="main-content content-login">
        <div className="form-login">
          <h1>{t("Email sent")}</h1>
          <p>
            {t(
              "An email is send to the specified email address. Click on the link in this email to reset your password.",
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sent;
