import firebase from "firebase/compat/app";
import { observable, runInAction } from "mobx";

import Profile from "./profile";
import User from "./user";
import Store from "..";

export { default as Profile } from "./profile";
export * from "./profile";
export { default as User } from "./user";

type Claim = { [collection: string]: string };

interface AuthData {
  profile?: Profile;
  user?: User;
  claim?: Claim;
}

class AuthStore {
  readonly store;
  private data = observable<AuthData>({});
  private loaded = observable.box(false);

  constructor(store: Store) {
    this.store = store;
    firebase.auth().onAuthStateChanged((user) => {
      this.updateUser(user);
    });
  }

  get isLoaded() {
    return (
      this.loaded.get() &&
      (!this.user || this.user.isLoaded) &&
      (!this.profile || this.profile.hasData)
    );
  }

  get email() {
    const user = firebase.auth().currentUser;
    return user && user.email;
  }

  get isSignedIn() {
    const user = firebase.auth().currentUser;
    return !!user && !user.isAnonymous;
  }

  get isNewUser() {
    const user = firebase.auth().currentUser;
    return user?.metadata.creationTime === user?.metadata.lastSignInTime;
  }

  get isRegComplete() {
    return (
      // typeof this.profile?.notificationPreferences !== "undefined" &&
      typeof this.email !== "undefined" &&
      this.profile?.userAgreement?.dateAccepted
    );
  }

  get isAdmin() {
    return this.data.claim;
  }

  get userId() {
    const user = firebase.auth().currentUser;
    return user && user.uid;
  }

  get profile() {
    return this.data.profile;
  }

  get user() {
    return this.data.user;
  }

  private updateUser = async (user: firebase.User | null) => {
    const previousUser = firebase.auth().currentUser;
    if (previousUser?.isAnonymous && !user?.isAnonymous) {
      try {
        await firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("linkUser")(previousUser.uid);
        // previousUser.delete();
      } catch (error) {
        console.error("Link failed: ", error);
      }
    }
    try {
      const token = await user?.getIdTokenResult(true);
      runInAction(() => {
        console.debug("User: ", user ? user.uid : "-");
        this.data.user = user
          ? new User(`user/${user.uid}`, {}, this.store)
          : undefined;
        this.data.profile =
          user && !user.isAnonymous
            ? new Profile(`profile/${user.uid}`, {}, this.store)
            : undefined;

        console.debug("User claims: ", token?.claims?.access);
        this.data.claim = token?.claims?.access;

        this.loaded.set(true);
        this.fadeOutLoader();
      });

      const language = this.store.i18n.language;
      this.data.profile?.ready().then(() => {
        if (this.data.profile?.language !== language) {
          this.data.profile?.update({ language });
        }
      });
    } catch (error) {
      console.debug("Sign in error: ", error);
    }
  };

  private signInPopUp = async (provider: firebase.auth.AuthProvider) => {
    return await firebase.auth().signInWithPopup(provider);
  };

  fadeOutLoader() {
    const fadeTarget = document.getElementById("preloader");
    const fadeEffect = setInterval(function () {
      if (fadeTarget && !fadeTarget.style.opacity) {
        fadeTarget.style.opacity = "1";
      }
      const opacity =
        fadeTarget && fadeTarget.style.opacity
          ? parseFloat(fadeTarget.style.opacity)
          : 0;
      if (fadeTarget && opacity > 0) {
        fadeTarget.style.opacity = `${opacity - 0.1}`;
      } else {
        fadeTarget && fadeTarget.remove();
        clearInterval(fadeEffect);
      }
    }, 20);
  }

  signInAnonymously = async () => {
    await firebase.auth().signInAnonymously();
  };

  signInEmail = async (data: { email: string; password: string }) => {
    const { email, password } = data;
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  signInFacebook = () =>
    new Promise(async (resolve, reject) => {
      try {
        // firebase.auth().getRedirectResult().then(resolve);
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope("email");
        provider.addScope("user_link");
        this.signInPopUp(provider);
      } catch (error) {
        const { message } = error as Error;
        console.error("Facebook error: ", message);
        reject(error);
      }
    });

  signInGoogle = () =>
    new Promise((resolve, reject) => {
      try {
        // firebase.auth().getRedirectResult().then(resolve);
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        this.signInPopUp(provider);
      } catch (error) {
        const { message } = error as Error;
        console.error("Google error: ", message);
        reject(error);
      }
    });

  signInTwitter = () =>
    new Promise((resolve, reject) => {
      try {
        // firebase.auth().getRedirectResult().then(resolve);
        const provider = new firebase.auth.TwitterAuthProvider();
        this.signInPopUp(provider);
      } catch (error) {
        const { message } = error as Error;
        console.error("Twitter error: ", message);
        reject(error);
      }
    });

  signUp = async (data: { email: string; password: string }) => {
    const { email, password } = data;
    await firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  signOut = () => {
    return firebase.auth().signOut();
  };

  resetPassword = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
  };
}

export default AuthStore;
