import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useStore } from "../../store";

interface Segment {
  title?: string;
  url?: string;
}

interface Props {
  title?: string;
  segments?: Segment[];
  image?: string;
  showAll?: boolean;
}

const Header = (props: Props) => {
  const {
    segments,
    image,
    showAll = document.URL.includes("/league/"),
  } = props;
  const { t } = useTranslation();
  const { data } = useStore();

  const renderBreadcrumbs = () => {
    const content = [
      showAll && (
        <li key="all-campaigns">
          <Button
            href={`/${data.country?.id}/explore`}
            size="sm"
            variant="outline-light"
          >
            {t("All Campaigns")}
          </Button>
        </li>
      ),
    ];
    if (segments?.length) {
      segments.forEach((segment) => {
        const { url, title } = segment;
        if (url && title) {
          content.push(
            <li key={`separator-${url}`}>
              <span>&#62;</span>
            </li>,
            <li key={`link-${url}`}>
              <Button href={url ?? ""} size="sm" variant="outline-light">
                {title}
              </Button>
            </li>,
          );
        }
      });
    }
    return (
      <div className="breadcrumbs">
        <ul>{content}</ul>
      </div>
    );
  };
  const style = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className="page-title" style={style}>
      <div className="container">
        <h1>{props.title}</h1>
        {renderBreadcrumbs()}
      </div>
    </div>
  );
};

export default Header;
