import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate, Outlet } from "react-router-dom";

import { useStore } from "../../store";

const Admin = () => {
  const { auth } = useStore();
  const navigate = useNavigate();
  if (!auth.isLoaded) {
    return <div />;
  }
  if (!auth.isAdmin) {
    navigate("/");
    return <div />;
  } else {
    return (
      <div>
        <Outlet />
      </div>
    );
  }
};

export default observer(Admin);
