import { observer } from "mobx-react-lite";
import { Container, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

import Disclaimer from "./disclaimer";
import Payment from "./payment";
import Receipt from "./receipt";
import { Fund } from "../../store";

interface Props {
  fund: Fund;
}
const Checkout = (props: Props) => {
  const { fund } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const renderPayment = () => {
    return (
      <div className="accordion" id="">
        <Payment fund={fund} />
      </div>
    );
  };

  return (
    <Container>
      <Row>
        <Col lg={7} md={5} xs={{ span: 12, order: 2 }}>
          {renderPayment()}
        </Col>
        <Col lg={5} md={7} xs={{ span: 12, order: 1 }}>
          <Receipt fund={fund} />
          {!isMobile && <Disclaimer />}
        </Col>
        {isMobile && (
          <Col xs={{ span: 12, order: 3 }}>
            <Disclaimer />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default observer(Checkout);
