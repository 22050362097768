import classNames from "classnames";
import React, {
  DetailedHTMLProps,
  SelectHTMLAttributes,
  ChangeEvent,
} from "react";

interface Props
  extends Omit<
    DetailedHTMLProps<
      SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    "onChange"
  > {
  invalid?: boolean;
  onChange?: (value: string) => void;
}
const Select = (props: Props) => {
  const { invalid, onChange, ...otherProps } = props;

  const selectClass = classNames("form-control", {
    "is-invalid": invalid,
    "is-empty": !props.value,
  });

  const update = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select className={selectClass} onChange={update} {...otherProps}>
      {props.children}
    </select>
  );
};

export default Select;
