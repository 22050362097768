import { TFunction } from "i18next";

export class CustomError extends Error {
  code?: string;

  constructor(message: string, code?: string) {
    super(message);
    this.code = code;
  }
}

export const localizedError = (error: CustomError, t: TFunction) => {
  const { code } = error;
  switch (code) {
    case "auth/user-not-found":
      return t(
        "There is no account with this email address. Please look if you used another address or create a new account.",
      );
    case "auth/invalid-email":
      return t("The specified email address is not a valid email address");
    case "auth/wrong-password":
      return t("The password is not valid");
    case "auth/weak-password":
      return t("The password must consist of at least 6 characters long");
    case "auth/email-already-in-use":
      return t("There is already an account on this email adress");
    case "payment/method-ideal-no-issuer":
      return t("Select your bank first");
    default:
      console.debug("Unknown error: ", error);
      return t("Something went wrong, please try again");
  }
};
