import classNames from "classnames";
import { uniq } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  Form,
  Alert,
  Row,
  Col,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";

import { localizedError } from "../../../helpers/error";
import {
  useStore,
  Campaign,
  CampaignAddData,
  LocalizedString,
} from "../../../store";
import Avatar from "../../general/avatar";
import DateInput from "../../general/dateinput";
import Input from "../../general/input";
import NumberInput from "../../general/numberinput";
import Select from "../../general/select";
import Textarea from "../../general/textarea";

interface Value<T> {
  value: T;
  isValid: boolean;
  message: string;
}

interface Image extends File {
  preview: string;
}

interface Props {
  campaign?: Campaign;
}
const AdminCampaignForm = (props: Props) => {
  const { campaign } = props;
  const store = useStore();
  const [cashed, setCashed] = useState<Value<boolean>>({
    value: campaign?.data.cashed || false,
    isValid: true,
    message: "",
  });
  const [club, setClub] = useState<Value<string>>({
    value: campaign?.club?.id || "",
    isValid: true,
    message: "",
  });
  const [title, setTitle] = useState<Value<LocalizedString>>({
    value: campaign?.data.title || {},
    isValid: true,
    message: "",
  });
  const [description, setDescription] = useState<Value<LocalizedString>>({
    value: campaign?.data.description || {},
    isValid: true,
    message: "",
  });
  const [goal, setGoal] = useState<Value<number | undefined>>({
    value: campaign?.goal ? campaign.goal : undefined,
    isValid: true,
    message: "",
  });
  const [createdAt, setCreatedAt] = useState<Value<Date>>({
    value: campaign?.createdAt || new Date(),
    isValid: true,
    message: "",
  });
  const [endAt, setEndAt] = useState<Value<Date>>({
    value: campaign?.endAt || new Date(),
    isValid: true,
    message: "",
  });
  const [videoUrl, setVideoUrl] = useState<Value<LocalizedString>>({
    value: campaign?.data.videoUrl || {},
    isValid: true,
    message: "",
  });
  const [images, setImages] = useState<Value<string[] | undefined>>({
    value: campaign?.images,
    isValid: true,
    message: "",
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
  });
  const languages = uniq([
    ...Object.keys(title.value),
    ...Object.keys(description.value),
  ]);
  const availableLanguges = store.i18n.languages.filter(
    (lang) => languages.indexOf(lang) < 0,
  );
  const [language, setLanguage] = useState(languages[0]);
  const [showLanguage, setShowLanguage] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { country } = store.data;
  const leagues = country?.leagues.docs || [];
  const [leagueIndex, setLeagueIndex] = useState(0);
  const clubs = leagues[leagueIndex]?.clubs.docs;
  const [rejectValue, setRejectValue] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const currencyPrefix = country?.displayCurrencyPrefix;

  const [uploads, setUploads] = useState<Image[] | undefined>();

  useEffect(() => {
    setUploads(
      acceptedFiles?.map(
        (file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) }) as Image,
      ),
    );
  }, [acceptedFiles]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const updateRejectValue = (value: string) => {
    setRejectValue(value);
    setRejectReason("");
    if (value !== "3") {
      setRejectModal(true);
    } else {
      sendRejectNotification();
    }
  };

  const updateCashed = (value: boolean) => {
    setCashed({ ...cashed, value });
  };

  const updateClub = (value: string) => {
    setClub({ ...club, value });
  };

  const updateTitle = (value: string) => {
    setTitle({ ...title, value: { [language]: value } });
  };

  const updateDescription = (value: string) => {
    setDescription({ ...description, value: { [language]: value } });
  };

  const updateGoal = (value?: number) => {
    setGoal({ ...goal, value: value ? value * 100 : undefined });
  };

  const updateCreatedAt = (value: Date) => {
    setCreatedAt({ ...createdAt, value });
  };

  const updateEndAt = (value: Date) => {
    setEndAt({ ...endAt, value });
  };

  const updatevideoUrl = (value: string) => {
    setVideoUrl({ ...videoUrl, value: { [language]: value } });
  };

  const updateLeague = (index: number) => () => {
    setLeagueIndex(index);
    updateClub("");
  };

  const addLanguage = (lang: string) => {
    setTitle({ ...title, value: { ...title.value, [lang]: "" } });
    setDescription({
      ...description,
      value: { ...description.value, [lang]: "" },
    });
    setLanguage(lang);
    setShowLanguage(false);
  };

  const updateReason = (value: string) => {
    setRejectReason(value);
  };

  const isValid = () => {
    const clubValid = clubIsValid();
    const titleValid = titleIsValid();
    const descriptionValid = descriptionIsValid();
    const goalValid = goalIsValid();
    const imageValid = imagesIsValid();
    const endAtValid = endAtIsValid();
    const videoUrlValid = videoUrlIsValid();
    return (
      clubValid &&
      titleValid &&
      descriptionValid &&
      goalValid &&
      imageValid &&
      endAtValid &&
      videoUrlValid
    );
  };

  const clubIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(club.value)) {
      isValid = false;
      message = t("Choose a club");
    }
    setClub({ ...club, isValid, message });
    return isValid;
  };
  const titleIsValid = () => {
    const isValid = true;
    const message = "";
    // FIXME
    // if (validator.isEmpty(title.value)) {
    //   isValid = false;
    //   message = t("Enter a heading");
    // }
    setTitle({ ...title, isValid, message });
    return isValid;
  };
  const descriptionIsValid = () => {
    const isValid = true;
    const message = "";
    // FIXME
    // if (validator.isEmpty(description.value)) {
    //   isValid = false;
    //   message = t("Enter a description");
    // }
    setDescription({ ...description, isValid, message });
    return isValid;
  };

  const goalIsValid = () => {
    let isValid = true;
    let message = "";
    if (!goal?.value || goal.value <= 0) {
      isValid = false;
      message = t("Enter a valid amount");
    }
    setGoal({ ...goal, isValid, message });
    return isValid;
  };

  const endAtIsValid = () => {
    let isValid = true;
    let message = "";

    if (!endAt.value) {
      isValid = false;
      message = t("Enter a valid end date");
    }
    setEndAt({ ...endAt, isValid, message });
    return isValid;
  };

  const videoUrlIsValid = () => {
    const isValid = true;
    const message = "";
    setVideoUrl({ ...videoUrl, isValid, message });
    return isValid;
  };

  const imagesIsValid = () => {
    const isValid = true;
    // let message = "";
    // if (!image.value && !upload) {
    //   isValid = false;
    //   message = t("Choose a picture");
    // }
    // setImage({ ...image, isValid, message });
    return isValid;
  };

  const removeImages = () => {
    setUploads(undefined);
    setImages({ ...images, value: undefined });
  };

  const data = () =>
    ({
      title: title.value,
      description: description.value,
      goal: goal.value,
      images: images.value,
      userId: campaign?.user?.id ?? store.auth.userId,
      uploads,
      endAt: endAt.value,
      createdAt: createdAt.value,
      videoUrl: videoUrl.value,
      cashed: cashed.value,
      clubId: campaign?.clubId,
      leagueId: campaign?.leagueId,
    }) as CampaignAddData;

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const save = async () => {
    if (isValid()) {
      try {
        setLoading(true);
        if (!store.auth.userId) throw new Error("Not authorized");
        await campaign?.club?.addCampaign({
          ...data(),
          id: campaign?.id,
        });
        setLoading(false);
        navigate(-1);
      } catch (error) {
        runInAction(() => {
          setError(localizedError(error as Error, t));
          setLoading(false);
        });
      }
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const togglePublish = async () => {
    if (campaign?.isPublished) {
      campaign?.unpublish();
    } else {
      campaign?.publish();
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const filterFutureDate = (date: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() > selectedDate.getTime();
  };

  const filterPassedDate = (date: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const rejectActions: any = [
    { id: "1", title: "Send and Retain" },
    { id: "2", title: "Send and Remove" },
    { id: "3", title: "Delete" },
  ];

  const renderClub = () => {
    return (
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline-secondary"
          title={leagues[leagueIndex]?.title || ""}
          className={classNames({ "is-invalid": !club.isValid })}
        >
          {leagues?.map((league, index) => (
            <Dropdown.Item key={league.id} onClick={updateLeague(index)}>
              {league.title}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Select
          onChange={updateClub}
          invalid={!club.isValid}
          value={club.value}
        >
          <option key="empty" value="">
            {t("Select a club")}
          </option>
          {clubs?.map((aClub) => {
            return (
              <option key={aClub.id} value={aClub.id}>
                {aClub.title}
              </option>
            );
          })}
        </Select>
      </InputGroup>
    );
  };

  const sendRejectNotification = async () => {
    await campaign?.reject(
      rejectReason,
      rejectValue === "2",
      rejectValue === "3",
    );
    navigate(-1);
  };

  const renderReport = () => (
    <Modal show={!!rejectModal}>
      <Modal.Header
        closeButton
        onClick={() => {
          setRejectModal(false);
          setRejectValue("");
        }}
      >
        <Modal.Title>Campaign Rejection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Textarea
          cols={30}
          rows={4}
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={updateReason}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          size="sm"
          onClick={() => {
            setRejectModal(false);
            setRejectValue("");
          }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => sendRejectNotification()}
          disabled={!rejectReason}
        >
          <Trans>Reject</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderUpload = () => {
    const logoClass = classNames("file-upload upload-bg", {
      "is-invalid": !images.isValid,
    });
    const previews = uploads?.length
      ? uploads.map((upload) => upload.preview)
      : images?.value;
    if (previews?.length) {
      return (
        <div className={logoClass}>
          <div className="upload-preview">
            {previews.map((preview, index) => (
              <img key={index} src={preview} alt={t("Example")} />
            ))}
            <Button variant="danger" onClick={removeImages}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={logoClass}>
          <div {...getRootProps({ className: "dropzone choicefile1" })}>
            <input {...getInputProps()} />
            <i className="fas fa-cloud-upload-alt" aria-hidden="true" />
            <div>
              <h4>{t("Upload")}</h4>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Form className="form-center" onSubmit={submit}>
      <Form.Group className="right">
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {language.toUpperCase()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {languages.map((lang) => (
              <Dropdown.Item key={lang} onClick={() => setLanguage(lang)}>
                {lang.toUpperCase()}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => setShowLanguage(true)}
              disabled={availableLanguges.length === 0}
            >
              <Trans>Add language</Trans>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Form.Check
          onChange={(e) => {
            updateCashed(e.currentTarget.checked);
          }}
          checked={cashed.value}
          className="my-4"
          defaultChecked={false}
          id={"user_agreement"}
          type="checkbox"
          label={t("Cashed")}
        />
        <Modal show={showLanguage} onHide={() => setShowLanguage(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Add language</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {availableLanguges.map((lang) => (
                <ListGroup.Item key={lang}>
                  <Row>
                    <Col>{lang.toUpperCase()}</Col>
                    <Col sm="auto">
                      {" "}
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => addLanguage(lang)}
                      >
                        <Trans>Add</Trans>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => setShowLanguage(false)}>
              <Trans>Cancel</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Club</Trans>
        </Form.Label>
        {renderClub()}
        <span className="invalid-feedback">{club.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Title</Trans>
        </Form.Label>
        <Input
          invalid={!title.isValid}
          type="text"
          value={title.value[language] || ""}
          onChange={updateTitle}
        />
        <span className="invalid-feedback">{title.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Description</Trans>
        </Form.Label>
        <Textarea
          invalid={!description.isValid}
          value={description.value[language] || ""}
          cols={30}
          rows={4}
          onChange={updateDescription}
        />
        <span className="invalid-feedback">{description.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Target amount donations</Trans>
        </Form.Label>
        <NumberInput
          invalid={!goal.isValid}
          value={goal?.value ? goal.value / 100 : undefined}
          prepend={currencyPrefix}
          onChange={updateGoal}
        />
        <span className="invalid-feedback">{goal.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Created date</Trans>
        </Form.Label>
        <DateInput
          invalid={!createdAt.isValid}
          selected={createdAt.value}
          onChange={updateCreatedAt}
          filterDate={filterFutureDate}
          showTimeSelect={true}
        />
        <span className="invalid-feedback">{createdAt.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Target end date</Trans>
        </Form.Label>
        <DateInput
          invalid={!endAt.isValid}
          selected={endAt.value}
          onChange={updateEndAt}
          filterDate={filterPassedDate}
        />
        <span className="invalid-feedback">{endAt.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Images</Trans>
          {renderUpload()}
          <small className="form-text text-muted">
            <Trans>png, jpg, gif with max. size of 5MB</Trans>
          </small>
          <span className="invalid-feedback">{images.message}</span>
        </Form.Label>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>Video URL</Trans>
        </Form.Label>
        <Input
          invalid={!videoUrl.isValid}
          type="url"
          value={videoUrl.value[language] || ""}
          onChange={updatevideoUrl}
        />
        <span className="invalid-feedback">{videoUrl.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <Trans>User</Trans>
        </Form.Label>
        <Alert variant="secondary">
          <Row>
            <Col sm="auto">
              <Avatar user={campaign?.user} size={40} />
            </Col>
            <Col>
              <Link to={campaign?.user?.id || ""}>
                <div>{campaign?.user?.displayName}</div>
                {campaign?.user?.email}
              </Link>
            </Col>
          </Row>
        </Alert>
      </Form.Group>
      <div className="d-flex justify-content-between">
        <Button variant="secondary" onClick={cancel}>
          {t("Cancel")}
        </Button>
        {!campaign?.isRejected && (
          <>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              onClick={save}
            >
              {loading ? t("Upload...") : t("Save")}
            </Button>
            <div style={{ width: "112px" }}>
              <Select
                onChange={(value) => updateRejectValue(value)}
                value={rejectValue}
                className="btn-secondary"
                style={{ cursor: "pointer" }}
              >
                <option key="empty" value="" disabled={true}>
                  {t("Reject")}
                </option>
                {rejectActions?.map((action: any) => {
                  return (
                    <option key={action.id} value={action.id}>
                      {t(action.title)}
                    </option>
                  );
                })}
              </Select>
            </div>
            <Button
              variant="secondary"
              onClick={togglePublish}
              className="publish"
              disabled={campaign?.isLoading}
            >
              {campaign?.isPublished ? t("Unpublish") : t("Publish")}
            </Button>
          </>
        )}
      </div>
      {renderError()}
      <p className="info-disclaimer">
        <Trans>
          After submitting your action, Clubmeister will review and moderate
          your support action and inform you when your support action is
          granted.
        </Trans>
      </p>
      {renderReport()}
    </Form>
  );
};

export default observer(AdminCampaignForm);
