import React, { useMemo } from "react";

import Header from "./header";
import Settings from "./settings";
import { Profile, useStore } from "../../../store";

interface Props {
  profile?: Profile;
}
const UserDetails = (props: Props) => {
  const { profile } = props;
  const { data } = useStore();
  const user = useMemo(
    () => (profile?.id ? data.admin.user(profile.id) : undefined),
    [profile?.id, data.admin],
  );

  if (!profile) return null;
  return (
    <div className="account-profile">
      <Header profile={profile} user={user} />
      <Settings user={user} />
    </div>
  );
};

export default UserDetails;
