import Profile from "../auth/profile";
import { Data } from "../general/data";
import Document from "../general/document";

export interface ReportData extends Data {
  text?: string;
}

class Report extends Document<ReportData> {
  private _user?: Profile;

  get text() {
    return this.data.text;
  }

  get createdAt() {
    return this.data.createdAt?.toDate();
  }
}

export default Report;
