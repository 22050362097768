import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import FundCheckout from "../../components/fund/checkout";
import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Checkout = () => {
  const { leagueId, clubId, campaignId, fundId } = useParams();
  const { data } = useStore();
  const fund = useMemo(
    () =>
      leagueId && clubId && campaignId && fundId
        ? data.fund(leagueId, clubId, campaignId, fundId)
        : undefined,
    [leagueId, clubId, campaignId, fundId, data],
  );

  const isLoaded =
    data.country &&
    data.country.isLoaded &&
    fund?.isLoaded &&
    fund?.campaign &&
    fund?.campaign.isLoaded &&
    fund?.campaign.club &&
    fund?.campaign.club.isLoaded;

  if (!fund || !isLoaded) return <Preloader />;
  return (
    <ScrollUp>
      <main id="main" className="site-main">
        <div className="billing-detail">
          <GeneralHeader
            title={fund?.campaign?.title}
            segments={[
              {
                title: fund?.campaign?.club?.league?.title,
                url: `${fund?.campaign?.club?.league?.link}/explore`,
              },
              {
                title: fund?.campaign?.club?.title,
                url: `${fund?.campaign?.club?.link}/explore`,
              },
            ]}
            image={fund?.campaign?.club?.image}
          />
          <FundCheckout fund={fund} />
        </div>
      </main>
    </ScrollUp>
  );
};
export default observer(Checkout);
