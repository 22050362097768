import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import CampaignDiscover, { Filter } from "../../components/explore/discover";
import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Explore = () => {
  const { leagueId, clubId } = useParams();
  const { data } = useStore();
  const { country } = data;
  const club = useMemo(
    () => (leagueId && clubId ? country?.club(leagueId, clubId) : undefined),
    [leagueId, clubId, country],
  );

  const filter = {
    club,
  } as Filter;

  if (!club || !club.isLoaded) return <Preloader />;
  return (
    <ScrollUp>
      <main id="main" className="site-main">
        <div className="explore-club">
          <GeneralHeader
            title={club.title}
            segments={[
              {
                title: club?.league?.title,
                url: `${club?.league?.link}/explore`,
              },
            ]}
            image={club.image}
          />
          <CampaignDiscover hide={["club", "league"]} filter={filter} />
        </div>
      </main>
    </ScrollUp>
  );
};

export default observer(Explore);
