import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";

import AdminCampaign from "../../../components/admin/campaign";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const Campaign = () => {
  const { data } = useStore();
  const campaigns = useAsync(() => {
    return data.admin.campaigns();
  }, []);

  if (campaigns?.loading) return <Preloader />;

  return (
    <div className="admin">
      <AdminCampaign campaigns={campaigns.result || []} />
    </div>
  );
};

export default observer(Campaign);
