import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Tab {
  id: string;
  title: string;
  to: string;
}

interface Props {
  children: ReactNode;
}

const Settings = (props: Props) => {
  const { children } = props;
  const { t } = useTranslation();
  const tabs = [
    {
      id: "funds",
      title: t("Your donations"),
      to: "",
    },
    {
      id: "campaigns",
      title: t("Your support actions"),
      to: "campaigns",
    },
    {
      id: "notifications",
      title: t("Notifications"),
      to: "notifications",
    },
    {
      id: "settings",
      title: t("Settings"),
      to: "settings",
    },
  ];

  const renderTab = (tab: Tab) => {
    return (
      <Link key={tab.id} data-filter={tab.id} to={tab.to}>
        {tab.title}
      </Link>
    );
  };

  return (
    <div className="container">
      <div className="settings-tabs">{tabs.map(renderTab)}</div>
      {children}
    </div>
  );
};

export default Settings;
