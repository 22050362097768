import firebase from "firebase/compat/app";
import { Collection } from "firestorter";
import { v4 as uuid } from "uuid";

import { Country } from ".";
import Club, { ClubData } from "./club";
import { Data } from "../general/data";
import Document from "../general/document";

export interface LeagueData extends Data {
  title: string;
  logo?: string;
  image?: string;
}

interface ClubAddData extends Omit<Omit<ClubData, "createdAt">, "updatedAt"> {
  upload?: Blob;
  id?: string;
}

export interface Query {
  where?: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.WhereFilterOp,
    any,
  ];
  orderBy?: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.OrderByDirection,
  ];
  limit?: number;
}

class League extends Document<LeagueData> {
  private _clubs?: Collection<Club>;
  private _country?: Country;

  get link() {
    const path = this.path as string | undefined;
    return path?.replace("country", "") || "";
  }

  get title() {
    return this.data.title;
  }

  get logo() {
    return this.data.logo;
  }
  get image() {
    return this.data.image;
  }

  get clubs() {
    if (!this._clubs) {
      this._clubs = new Collection(() => `${this.path}/club`, {
        query: (ref: firebase.firestore.CollectionReference) =>
          ref.orderBy("title"),
        createDocument: (source, options) =>
          new Club(source, options, this.store),
      });
    }
    return this._clubs;
  }

  get country() {
    if (!this._country && this.ref?.parent?.parent) {
      this._country = new Country(this.ref?.parent?.parent, {}, this.store);
    }
    return this._country;
  }

  club = (clubId: string) => {
    if (!this.id) return undefined;
    return new Club(`${this.path}/club/${clubId}`, {}, this.store);
  };

  addClub = async (data: ClubAddData) => {
    const { upload, id, ...otherData } = data;
    const logo =
      (upload &&
        (await firebase
          .storage()
          .ref()
          .child(`${this.path}/club/${uuid()}`)
          .put(upload)
          .then((snapshot) => snapshot.ref.getDownloadURL()))) ||
      data.logo;
    if (id) {
      await firebase
        .firestore()
        .collection(`${this.path}/club`)
        .doc(id)
        .set(
          {
            ...otherData,
            logo,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
    } else {
      await firebase
        .firestore()
        .collection(`${this.path}/club`)
        .add({
          ...otherData,
          logo,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };
}

export default League;
