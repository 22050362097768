import React from "react";

import Menu from "./menu";

interface Props {}
const Top = (props: Props) => {
  return (
    <header id="header" className="site-header">
      <Menu />
    </header>
  );
};

export default Top;
