import React, { SyntheticEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, RouteProps } from "react-router-dom";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { useStore } from "../../store";
import Input from "../general/input";

const Forgot = (props: RouteProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth } = useStore();
  const [email, setEmail] = useState({ value: "", isValid: true, message: "" });
  const [error, setError] = useState<string>();

  const updateEmail = (value: string) => {
    setEmail({ ...email, value });
  };

  const isValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(email.value)) {
      message = t("Enter your email address");
      isValid = false;
    } else if (!validator.isEmail(email.value)) {
      message = t("Invalid email address");
      isValid = false;
    }
    setEmail({ ...email, isValid, message });
    return isValid;
  };

  const signIn = async () => {
    if (isValid()) {
      try {
        await auth.resetPassword(email.value);
        navigate("/signin/sent");
      } catch (error) {
        setError(localizedError(error as Error, t));
      }
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="main-content content-login">
        <div className="form-login">
          <h1>{t("Reset your password")}</h1>
          <p>
            {t(
              "Enter your email address. If there already is an account with this address you will receive an email with a link to reset your password.",
            )}
          </p>
          <form noValidate onSubmit={submit}>
            <div className="field">
              <Input
                type="email"
                value={email.value}
                placeholder={t("email adress")}
                invalid={!email.isValid}
                onChange={updateEmail}
              />
              <span className="invalid-feedback">{email.message}</span>
            </div>
            <div className="inline clearfix">
              <Button type="submit" variant="primary" onClick={signIn}>
                {t("Send")}
              </Button>
              <Button type="submit" variant="secondary" onClick={cancel}>
                {t("Cancel")}
              </Button>
            </div>
            {renderError()}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
