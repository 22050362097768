import React from "react";

interface Props {
  inline?: boolean;
}
const Preloader = (props: Props) => {
  const { inline } = props;

  const renderLoader = () => (
    <div className="preloader loading">
      <span className="slice" />
      <span className="slice" />
      <span className="slice" />
      <span className="slice" />
      <span className="slice" />
      <span className="slice" />
    </div>
  );
  if (inline) return renderLoader();
  return (
    <div className="preloading" id="preloader">
      {renderLoader()}
    </div>
  );
};

export default Preloader;
