import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import CampaignComments from "../../components/campaign/comments";
import CampaignSummary from "../../components/campaign/summary";
import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import Config from "../../config.json";
import { useStore } from "../../store";

const Details = () => {
  const { campaignId, clubId, leagueId } = useParams();
  const { data } = useStore();
  const campaign = useMemo(
    () =>
      leagueId && clubId && campaignId
        ? data.campaign(leagueId, clubId, campaignId)
        : undefined,
    [data, leagueId, clubId, campaignId],
  );
  if (!campaign || !campaign.isLoaded) return <Preloader />;

  return (
    <ScrollUp>
      <Helmet>
        <title>{`Clubmeister - ${campaign?.title}`}</title>
        <meta name="og:url" content={window.location.href} />
        <meta name="og:title" content={campaign?.title} />
        <meta name="og:description" content={campaign?.description} />
        <meta name="description" content={campaign?.description} />
        <meta name="og:image" content={campaign?.image} />
        <meta name="og:type" content="article" />
        <meta name="fb:app_id" content={Config.social.facebook.appId} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main id="main" className="site-main">
        <div className="campaign-detail">
          <GeneralHeader
            title={campaign?.title}
            segments={[
              {
                title: campaign?.club?.league?.title,
                url: `${campaign?.club?.league?.link}/explore`,
              },
              {
                title: campaign?.club?.title,
                url: `${campaign?.club?.link}/explore`,
              },
            ]}
            image={campaign?.club?.image}
          />
          <CampaignSummary campaign={campaign} />
          <CampaignComments campaign={campaign} />
        </div>
      </main>
    </ScrollUp>
  );
};

export default observer(Details);
