import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Profile, User } from "../../../store";
import Avatar from "../../general/avatar";

interface Props {
  profile: Profile;
  user?: User;
}
const Header = (props: Props) => {
  const { profile, user } = props;
  const { t } = useTranslation();

  const renderAvatar = () => {
    return (
      <div className="photo">
        <Avatar user={profile} size={150} />
      </div>
    );
  };

  const renderName = () => {
    return <h1>{profile?.displayName}</h1>;
  };

  const block = async () => {
    await user?.set({ blocked: true }, { merge: true });
  };
  const unblock = async () => {
    await user?.set({ blocked: false }, { merge: true });
  };

  const renderButtons = () => {
    return user?.blocked ? (
      <Button size="sm" variant="outline-primary" onClick={unblock}>
        {t("Unblock")}
      </Button>
    ) : user ? (
      <Button size="sm" variant="outline-primary" onClick={block}>
        {t("Block")}
      </Button>
    ) : null;
  };

  return (
    <div className="header">
      {renderAvatar()}
      <div>
        {renderName()}
        <p>{profile?.email}</p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default observer(Header);
