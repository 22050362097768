import { autorun } from "mobx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FundComplete from "../../components/fund/complete";
import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Complete = () => {
  const { t } = useTranslation();
  const { leagueId, clubId, campaignId, fundId } = useParams();
  const { data } = useStore();
  const [fund] = useState(
    leagueId && clubId && campaignId && fundId
      ? data.fund(leagueId, clubId, campaignId, fundId)
      : undefined,
  );
  const [loaded, setLoaded] = useState(false);
  autorun(() => {
    const isLoaded =
      data.country?.isLoaded &&
      fund?.isLoaded &&
      fund?.campaign?.isLoaded &&
      fund?.campaign?.club?.isLoaded;
    if (isLoaded !== loaded) setLoaded(!!isLoaded);
  });
  if (!loaded || !fund) return <Preloader />;

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} -{" "}
          {fund.isFailed
            ? t("Something went wrong with the transaction of your donation.")
            : t("Thank you for your donation")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="billing-detail">
          <GeneralHeader
            title={fund?.campaign?.title}
            segments={[
              {
                title: fund?.campaign?.club?.league?.title,
                url: `${fund?.campaign?.club?.league?.link}/explore`,
              },
              {
                title: fund?.campaign?.club?.title,
                url: `${fund?.campaign?.club?.link}/explore`,
              },
            ]}
            image={fund?.campaign?.club?.image}
          />
          <FundComplete fund={fund} />
        </div>
      </main>
    </ScrollUp>
  );
};
export default Complete;
