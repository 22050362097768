import firebase from "firebase/compat/app";

import Admin from "./admin";
import Campaign from "./campaign";
import Country from "./country";
import Fund from "./fund";
import Store from "..";

export { default as Country } from "./country";
export * from "./country";
export { default as League } from "./league";
export { default as Club } from "./club";
export * from "./club";
export { default as Campaign } from "./campaign";
export * from "./campaign";
export { default as Comment } from "./comment";
export { default as Fund } from "./fund";
export { default as Payment } from "./payment";
export { default as Report } from "./report";

export interface MessageData {
  name: string;
  email: string;
  subject: string;
  message: string;
  toMail: string;
}

class DataStore {
  readonly store;
  private _country?: Country;
  readonly admin: Admin;

  constructor(store: Store) {
    this.store = store;
    this.admin = new Admin(this.store);
  }

  set countryId(countryId: string) {
    this._country = new Country(`country/${countryId}`, {}, this.store);
  }

  get country() {
    return this._country;
  }

  campaign = (leagueId: string, clubId: string, campaignId: string) => {
    if (!this.country?.id) return undefined;
    return new Campaign(
      `country/${this.country?.id}/league/${leagueId}/club/${clubId}/campaign/${campaignId}`,
      {},
      this.store,
    );
  };

  fund = (
    leagueId: string,
    clubId: string,
    campaignId: string,
    fundId: string,
  ) => {
    if (!this.country?.id) return undefined;
    return new Fund(
      `country/${this.country?.id}/league/${leagueId}/club/${clubId}/campaign/${campaignId}/fund/${fundId}`,
      {},
      this.store,
    );
  };

  thanksImage = async () => {
    const storage = firebase.storage().ref();
    const { items } = await storage.child("thanks").listAll();
    const index = Math.floor(Math.random() * items.length);
    const url = await items[index].getDownloadURL();
    return url;
  };

  headerImage = async () => {
    const storage = firebase.storage().ref();
    const { items } = await storage.child("general").listAll();
    const index = Math.floor(Math.random() * items.length);
    const url = await items[index].getDownloadURL();
    return url;
  };

  sendMessage = (data: MessageData) => {
    const { message, email, ...otherData } = data;
    const country = this.country?.id;
    const messageData = {
      userMail: email,
      type: "text",
      content: {
        text: message,
      },
      source: {
        ...otherData,
        country,
      },
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("contactForm")({
      messageData,
    });
  };
}

export default DataStore;
