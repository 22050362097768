import Markdown from "markdown-to-jsx";
import { useAsync } from "react-async-hook";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";

import GeneralHeader from "../components/general/header";
import ScrollUp from "../components/general/scrollup";
import { useStore } from "../store";

const Clubs = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const image = useAsync(data.headerImage, []);

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("For clubs")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="campaign-detail">
          <GeneralHeader image={image.result} />
          <div className="container">
            <div className="main-content">
              <h1 className="title">
                <Trans>For clubs</Trans>
              </h1>
              <div className="terms">
                <Markdown className="markdown">{t("md_for_clubs")}</Markdown>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ScrollUp>
  );
};

export default Clubs;
