import classNames from "classnames";
import first from "lodash/first";
import { runInAction } from "mobx";
import React, { useState, SyntheticEvent, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";

import { localizedError } from "../../../helpers/error";
import { useStore, League } from "../../../store";
import Input from "../../general/input";

interface Value<T> {
  value: T;
  isValid: boolean;
  message: string;
}
interface Image extends File {
  preview: string;
}

interface Props {
  league?: League;
}
const LeagueForm = (props: Props) => {
  const { league } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
  });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<Value<string>>({
    value: league?.title || "",
    isValid: true,
    message: "",
  });

  const [logo, setLogo] = useState<Value<string | undefined>>({
    value: league?.logo,
    isValid: true,
    message: "",
  });
  const [upload, setUpload] = useState<Image | undefined>();

  useEffect(() => {
    const file = first(acceptedFiles);
    setUpload(
      file
        ? (Object.assign(file, { preview: URL.createObjectURL(file) }) as Image)
        : undefined,
    );
  }, [acceptedFiles]);

  const cancel = () => {
    navigate(-1);
  };

  const updateTitle = (value: string) => {
    setTitle({ ...title, value });
  };

  const removeLogo = () => {
    setUpload(undefined);
    setLogo({ ...logo, value: undefined });
  };

  const isValid = () => {
    const nameValid = nameIsValid();
    const logoValid = logoIsValid();
    return nameValid && logoValid;
  };
  const nameIsValid = () => {
    let isValid = true;
    let message = "";

    if (validator.isEmpty(title.value)) {
      isValid = false;
      message = t("Enter your name");
    }
    setTitle({ ...title, isValid, message });
    return isValid;
  };
  const logoIsValid = () => {
    let isValid = true;
    let message = "";
    if (!logo.value && !upload) {
      isValid = false;
      message = t("Choose a logo");
    }
    setLogo({ ...logo, isValid, message });
    return isValid;
  };

  const save = async () => {
    if (isValid()) {
      try {
        setLoading(true);
        const data = {
          id: league?.id,
          title: title.value,
          logo: logo.value,
          upload,
        };
        await store.data.country?.addLeague(data);
        setLoading(false);
        navigate(-1);
      } catch (error) {
        runInAction(() => {
          setError(localizedError(error as Error, t));
          setLoading(false);
        });
      }
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const renderUpload = () => {
    const logoClass = classNames("file-upload upload-bg", {
      "is-invalid": !logo.isValid,
    });

    const preview = upload?.preview || logo.value;
    if (preview) {
      return (
        <div className={logoClass}>
          <div className="upload-preview">
            <img src={preview} alt={t("Example")} />
            <Button variant="danger" onClick={removeLogo}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={logoClass}>
          <div {...getRootProps({ className: "dropzone choicefile1" })}>
            <input {...getInputProps()} />
            <i className="fas fa-cloud-upload-alt" aria-hidden="true" />
            <div>
              <h4>{t("Upload")}</h4>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Form onSubmit={submit} className="admin-form">
      <Form.Group>
        <Form.Label>{t("Name")}</Form.Label>
        <Input
          invalid={!title.isValid}
          type="text"
          value={title.value}
          placeholder={t("Name of the league")}
          onChange={updateTitle}
        />
        <span className="invalid-feedback">{title.message}</span>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t("League logo")}</Form.Label>
        {renderUpload()}
        <span className="invalid-feedback">{logo.message}</span>
      </Form.Group>
      <Button disabled={loading} variant="secondary" onClick={cancel}>
        {t("Cancel")}
      </Button>
      <Button disabled={loading} type="submit" onClick={save}>
        {loading ? t("Upload...") : t("Save")}
      </Button>
      {renderError()}
    </Form>
  );
};

export default LeagueForm;
