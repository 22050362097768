import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { User, useStore } from "../../../store";
import Campaigns from "../campaign";
import Comments from "../comment";
import Funds from "../fund";

interface Props {
  user?: User;
}

const Settings = (props: Props) => {
  const { user } = props;
  const { data } = useStore();
  const { t } = useTranslation();
  const funds = useAsync(async () => await user?.funds(), [user]);
  const campaigns = useAsync(async () => await user?.campaigns(), [user]);
  const comments = useAsync(async () => await user?.comments(), [user]);

  const profiles = useAsync(() => {
    return data.admin.profiles();
  }, []);

  const tabs = [
    {
      id: "funds",
      title: t("Donations"),
      render: () => (
        <Funds
          funds={funds.result || []}
          profiles={profiles.result || []}
          campaigns={campaigns.result || []}
          fundPage={false}
        />
      ),
    },
    {
      id: "campaigns",
      title: t("Actions"),
      render: () => <Campaigns campaigns={campaigns.result || []} />,
    },
    {
      id: "comments",
      title: t("Comments"),
      render: () => <Comments comments={comments.result || []} reports={[]} />,
    },
    // {
    //   id: "notifications",
    //   title: t("Notifications"),
    // },
  ];

  return (
    <Tabs defaultActiveKey={tabs[0].id}>
      {tabs.map((tab) => (
        <Tab key={tab.id} eventKey={tab.id} title={tab.title}>
          {tab.render()}
        </Tab>
      ))}
    </Tabs>
  );
};

export default observer(Settings);
