import moment from "moment";
import React, { useState, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Task from "../../store/data/task";
import Input from "../general/input";

interface Props {
  task: Task;
}
const Index = (props: Props) => {
  const { task } = props;
  const [code, setCode] = useState({ value: "", isValid: true, message: "" });
  const [status, setStatus] = useState<string>();
  const { t } = useTranslation();

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const updateCode = (value: string) => {
    setCode({ ...code, value });
  };

  const checkCode = () => {
    if (code.value !== task.code) {
      setCode({ ...code, isValid: false, message: t("Invalid code") });
      setStatus(undefined);
      return;
    }
    setCode({ ...code, isValid: true, message: "" });
    setStatus(task.status);
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="form-login">
          <h1>{t("Status")}</h1>
          {status ? (
            <h4>
              {status === "completed"
                ? t("Task has been completed {{date}}", {
                    date: moment(task.updatedAt).fromNow(),
                  })
                : t("Task is pending")}
            </h4>
          ) : (
            <form noValidate onSubmit={submit}>
              <div className="field">
                <Input
                  invalid={!code.isValid}
                  value={code.value}
                  placeholder={t("Confirmation code")}
                  onChange={updateCode}
                />
                <span className="invalid-feedback">{code.message}</span>
              </div>
              <div className="inline clearfix">
                <Button type="submit" variant="primary" onClick={checkCode}>
                  {t("Confirm")}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
