import { observer } from "mobx-react-lite";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useAsync } from "react-async-hook";
import { Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStore, Campaign } from "../../store";

const Campaigns = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const campaigns = useAsync(
    async () => await auth.user?.campaigns(),
    [auth.user],
  );

  const renderCampaigns = (campaign: Campaign) => {
    const { id, createdAt, raised } = campaign;
    const currencyPrefix =
      campaign.club?.league?.country?.displayCurrencyPrefix;

    return (
      <tr key={id}>
        <td>
          <Row>
            <Col md={6}>{campaign?.title}</Col>
            <Col md={2}>
              {currencyPrefix} {numeral(raised / 100).format("0,0.00")}
            </Col>
            <Col md={2}>{createdAt ? moment(createdAt).fromNow() : ""}</Col>
            <Col md={2}>
              <Link to={campaign.link} className="active">
                {t("Check out support action")}
              </Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  const renderEmpty = () => {
    return (
      <tr>
        <td className="empty">{t("No actions found")}</td>
      </tr>
    );
  };
  return (
    <Table borderless={!campaigns?.result?.length}>
      <tbody>
        {campaigns?.result?.length
          ? campaigns.result.map(renderCampaigns)
          : renderEmpty()}
      </tbody>
    </Table>
  );
};

export default observer(Campaigns);
