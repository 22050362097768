import React from "react";
import { useCookies } from "react-cookie";
import { RouteProps, useLocation, matchPath } from "react-router-dom";

import Cookies from "./cookies";
import Footer from "./footer";
import Top from "./top";
import Password, { COOKIE_BETA_ACCESS } from "../../components/beta/password";
import Config from "../../config.json";
import AdminMenu from "../admin/menu";

const Main = (props: RouteProps) => {
  const { children } = props;
  const [cookie] = useCookies([COOKIE_BETA_ACCESS]);
  const password = cookie[COOKIE_BETA_ACCESS];
  const location = useLocation();
  const admin = matchPath({ path: "/:countryId/admin/*" }, location.pathname);

  if ((Config as any).beta && (Config as any).beta?.password !== password) {
    return <Password />;
  }
  return (
    <div className="page">
      <div id="wrapper">{admin ? <AdminMenu /> : <Top />}</div>
      <div className="body">{children}</div>
      <Footer />
      <Cookies />
    </div>
  );
};

export default Main;
