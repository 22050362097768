import first from "lodash/first";
import { runInAction } from "mobx";
import React, { useState, SyntheticEvent, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import * as XLSX from "xlsx";

import { localizedError } from "../../../helpers/error";
import { Club, League } from "../../../store";

interface Value<T> {
  value: T;
  isValid: boolean;
  message: string;
}
interface Image extends File {
  preview: string;
}

interface Props {
  league?: League;
  club?: Club;
}
const ClubFormAll = (props: Props) => {
  const { league, club } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { acceptedFiles } = useDropzone({
    accept: "image/*",
    multiple: false,
  });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<Value<string>>({
    value: club?.title || "",
    isValid: true,
    message: "",
  });

  const [logo, setLogo] = useState<Value<string | undefined>>({
    value: club?.logo,
    isValid: true,
    message: "",
  });
  const [upload, setUpload] = useState<Image | undefined>();

  useEffect(() => {
    const file = first(acceptedFiles);
    setUpload(
      file
        ? (Object.assign(file, { preview: URL.createObjectURL(file) }) as Image)
        : undefined,
    );
  }, [acceptedFiles]);

  const jsonFileUpload = async (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: any = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      for (let i = 0; i < jsonData.length; i++) {
        const clubData = jsonData[i];
        const club = {
          id: `${i}`,
          title: clubData.Club,
          logo: "",
        };
        try {
          await league?.addClub(club);
        } catch (error) {
          console.error(`Error uploading club ${club.title}:`, error);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const isValid = () => {
    const nameValid = nameIsValid();
    const logoValid = logoIsValid();
    return nameValid && logoValid;
  };
  const nameIsValid = () => {
    let isValid = true;
    let message = "";

    if (validator.isEmpty(title.value)) {
      isValid = false;
      message = t("Enter your name");
    }
    setTitle({ ...title, isValid, message });
    return isValid;
  };
  const logoIsValid = () => {
    let isValid = true;
    let message = "";
    if (!logo.value && !upload) {
      isValid = false;
      message = t("Choose a logo");
    }
    setLogo({ ...logo, isValid, message });
    return isValid;
  };

  const save = async () => {
    if (isValid()) {
      try {
        setLoading(true);
        const data = {
          id: club?.id,
          title: title.value,
          logo: logo.value,
          upload,
        };
        await league?.addClub(data);
        setLoading(false);
        navigate(-1);
      } catch (error) {
        runInAction(() => {
          setError(localizedError(error as Error, t));
          setLoading(false);
        });
      }
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };
  return (
    <Form onSubmit={submit} className="admin-form">
      <Form.Group>
        <input type="file" onChange={jsonFileUpload} />
      </Form.Group>
      <Button disabled={loading} type="submit" onClick={save}>
        {loading ? t("Upload...") : t("Save")}
      </Button>
      {renderError()}
    </Form>
  );
};

export default ClubFormAll;
