import { observer } from "mobx-react-lite";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

import { useStore, Fund } from "../../store";

const styles = {
  container: {},
};

interface Props {
  fund: Fund;
}

const Receipt = (props: Props) => {
  const { fund } = props;
  const { data } = useStore();
  const [setFixedAmount] = useState<any>(null);

  useEffect(() => {
    if (typeof setFixedAmount === "function") {
      setFixedAmount(data?.country?.addFund);
    }
  }, [data?.country?.addFund, setFixedAmount]);

  const { t } = useTranslation();
  const currencyPrefix =
    fund.campaign?.club?.league?.country?.displayCurrencyPrefix;

  const renderCostInfo = () => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {data.country?.displayApplicationFee(t)}
        </Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" overlay={popover} rootClose>
        <i className="fas fa-info-circle info"></i>
      </OverlayTrigger>
    );
  };

  // const renderTaxInfo = () => {
  //   const popover = (
  //     <Popover id="popover-basic">
  //       <Popover.Content>{data.country?.displayTax(t)}</Popover.Content>
  //     </Popover>
  //   );
  //   return (
  //     <OverlayTrigger trigger="click" overlay={popover} rootClose>
  //       <i className="fas fa-info-circle info"></i>
  //     </OverlayTrigger>
  //   );
  // };

  return (
    <div style={styles.container}>
      <div className="cart-totals">
        <h2>{t("Overview")}</h2>
        <p className="cart-description">
          <Trans i18nKey="donation_cost_info">
            Voor deze donatie hanteert Clubmeister een dienstenbijdrage. Klik op
            informatie voor uitleg.
          </Trans>
        </p>
        <ul>
          <li>
            <div className="row">
              <div className="col-8">
                <p>{t("Donation")}</p>
              </div>
              <div className="col-4">
                <p>
                  {currencyPrefix}{" "}
                  {numeral((fund.amount - fund.fee) / 100).format("0,0.00")}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="row">
              <div className="col-8">
                <p>{t("Transaction costs")}</p>
              </div>
              <div className="col-4">
                <p>
                  {currencyPrefix} {numeral(fund.fee / 100).format("0,0.00")}
                  {renderCostInfo()}
                </p>
              </div>
            </div>
          </li>
          <li className="total">
            <div className="row">
              <div className="col-8">
                <p>{t("Total (incl. VAT)")}</p>
              </div>
              <div className="col-4">
                <p>
                  {currencyPrefix} {numeral(fund.amount / 100).format("0,0.00")}
                </p>
              </div>
            </div>
          </li>
          {fund.tax ? (
            <li className="vat">
              <div className="row">
                <div className="col-8">
                  <p>{t("VAT")}</p>
                </div>
                <div className="col-4">
                  <p>
                    {currencyPrefix} {numeral(fund.tax / 100).format("0,0.00")}
                  </p>
                </div>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default observer(Receipt);
