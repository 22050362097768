import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import CampaignDiscover from "../components/explore/discover";
import ScrollUp from "../components/general/scrollup";
import HomeInfo from "../components/home/info";
import HomeSideshow from "../components/home/sideshow";

const Home = () => {
  const { t } = useTranslation();
  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("Your voice, your support, your club")}
        </title>
      </Helmet>
      <div className="home">
        <main id="main" className="site-main">
          <HomeSideshow />
          <HomeInfo />
          <CampaignDiscover hide={["club", "league"]} limit={6} />
        </main>
      </div>
    </ScrollUp>
  );
};
export default Home;
