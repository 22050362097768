import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";

import AdminComment from "../../components/admin/comment";
import Preloader from "../../components/general/preloader";
import { useStore } from "../../store";

const Comment = () => {
  const { data } = useStore();
  const comments = useAsync(() => {
    return data.admin.comments();
  }, []);
  const reports = useAsync(() => {
    return data.admin.reports();
  }, []);

  if (comments.loading || reports.loading) return <Preloader />;

  return (
    <div className="admin">
      <AdminComment
        comments={comments.result || []}
        reports={reports.result || []}
      />
    </div>
  );
};

export default observer(Comment);
