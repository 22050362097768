import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import AdminCampaignFund from "../../../components/admin/campaign/fund";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const Fund = () => {
  const { leagueId, clubId, campaignId } = useParams();
  const { data } = useStore();
  const [campaign] = useState(
    leagueId && clubId && campaignId
      ? data.campaign(leagueId, clubId, campaignId)
      : undefined,
  );

  if (campaign && !campaign.isLoaded) return <Preloader />;

  return (
    <div className="admin">
      <AdminCampaignFund campaign={campaign} />
    </div>
  );
};

export default observer(Fund);
