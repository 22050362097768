import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Outlet, RouteProps, useParams } from "react-router-dom";

import Main from "../components/general/main";
import Preloader from "../components/general/preloader";
import { useStore, Country } from "../store";

const CountryWrapper: FunctionComponent<RouteProps> = (props) => {
  const { countryId } = useParams();
  const { data } = useStore();
  const [country, setCountry] = useState<Country>();

  useEffect(() => {
    if (countryId) {
      data.countryId = countryId;
      setCountry(data.country);
    }
  }, [countryId, data]);

  if (!country?.isLoaded && !country?.title) return <Preloader />;
  return (
    <Main>
      <Outlet />
    </Main>
  );
};

export default observer(CountryWrapper);
