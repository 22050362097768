import firebase from "firebase/compat/app";
import { Collection } from "firestorter";

import { Fund, Campaign, Comment } from "../data";
import Report, { ReportData } from "../data/report";
import Document from "../general/document";

interface ReportAddData
  extends Omit<Omit<ReportData, "createdAt">, "updatedAt"> {
  comment: Comment;
}

interface UserData {
  stripe: {
    setup_secret: string;
  };
  blocked: boolean;
}

class User extends Document<UserData> {
  private _reports?: Collection<Report>;

  get blocked() {
    return this.data.blocked;
  }

  get stripeSetupSecret() {
    const { stripe } = this.data;
    if (!stripe) return undefined;
    const { setup_secret } = stripe;
    return setup_secret;
  }

  async funds() {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("fund")
        .where("userId", "==", this.id)
        .where("status", "==", "paid")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Fund(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading funds: ", error);
    }
  }

  async campaigns() {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("campaign")
        .where("userId", "==", this.id)
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Campaign(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading campaigns: ", error);
    }
  }

  async comments() {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("comment")
        .where("userId", "==", this.id)
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Comment(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading comments: ", error);
    }
  }

  get reports() {
    if (!this._reports) {
      this._reports = new Collection(() => `${this.path}/report`, {
        createDocument: (source, options) =>
          new Report(source, options, this.store),
      });
    }
    return this._reports;
  }

  reportComment = async (data: ReportAddData) => {
    const { comment, text } = data;
    await this.ref?.collection("report").doc(comment.id).set({
      text,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  deleteAccount = async () => {
    firebase
      ?.auth()
      ?.currentUser?.delete()
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          window.alert("Please sign-in and try again.");
          firebase.auth().signOut();
        }
      });
  };
}

export default User;
