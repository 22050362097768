import firebase from "firebase/compat/app";

import Campaign from "./campaign";
import { Profile } from "../auth";
import { Data } from "../general/data";
import Document from "../general/document";

export interface FundData {
  amount: number;
  currency: string;
}

export interface FundInternalData extends FundData, Data {
  userId: string;
  status:
    | "open"
    | "canceled"
    | "pending"
    | "authorized"
    | "expired"
    | "failed"
    | "verified"
    | "paid";
  refundStatus?: "pending" | "succeeded" | "failed";
  fee?: number;
  tax?: number;
  error?: string;
  add?: number;
}

interface PaymentDataBase {
  redirectUrl: string;
}

interface PaymentDataIdeal extends PaymentDataBase {
  method: "ideal";
  issuer: string;
}
interface PaymentDataCreditCard extends PaymentDataBase {
  method: "creditcard";
  cardToken: string;
}

export type PaymentData = PaymentDataIdeal | PaymentDataCreditCard;

class Fund extends Document<FundInternalData> {
  private _user?: Profile;
  private _campaign?: Campaign;

  get user() {
    if (this._user?.id !== this.data.userId) {
      this._user = new Profile(`profile/${this.data.userId}`, {}, this.store);
    }
    return this._user;
  }

  get campaign() {
    if (!this._campaign && this.ref?.parent?.parent) {
      this._campaign = new Campaign(this.ref?.parent?.parent, {}, this.store);
    }
    return this._campaign;
  }

  get link() {
    const path = this.path as string | undefined;
    return path?.replace("country", "") || "";
  }

  get isOpen() {
    return this.data.status === "open";
  }

  get isFailed() {
    return this.data.status === "failed";
  }

  get isCanceled() {
    return this.data.status === "canceled";
  }

  get isCompleted() {
    return this.data.status === "paid";
  }

  get isVerified() {
    return this.data.status === "verified";
  }

  get isExpired() {
    return this.data.status === "expired";
  }

  get status() {
    return this.data.status;
  }

  get amount() {
    return this.data.amount;
  }

  get currency() {
    return this.data.currency;
  }

  get refundStatus() {
    return this.data.refundStatus;
  }

  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  get fee() {
    return this.data.fee || 0;
  }

  get tax() {
    return this.data.tax;
  }

  get add() {
    return this.data.add || 0;
  }

  async createPayment(data: PaymentData) {
    const paymentData = {
      countryId: this.store.data.country?.id,
      leagueId: this.campaign?.club?.league?.id,
      clubId: this.campaign?.club?.id,
      campaignId: this.campaign?.id,
      fundId: this.id,
      locale: this.store.i18n.locale.replace("-", "_"),
      ...data,
    };
    const result = await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("paymentCreate")(paymentData);
    return result;
  }
}

export default Fund;
