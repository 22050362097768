import React, { SyntheticEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Link, useLocation, useNavigate, RouteProps } from "react-router-dom";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { getQuery } from "../../helpers/tools";
import { useStore } from "../../store";
import Consent from "../general/consent";
import Input from "../general/input";

const SignUp = (props: RouteProps) => {
  const [email, setEmail] = useState({ value: "", isValid: true, message: "" });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    message: "",
  });
  const [error, setError] = useState<string>();
  const { auth } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const updateEmail = (value: string) => {
    setEmail({ ...email, value });
  };

  const updatePassword = (value: string) => {
    setPassword({ ...password, value });
  };

  const isValid = () => {
    const emailValid = emailIsValid();
    const passwordValid = passwordIsValid();
    return emailValid && passwordValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(email.value)) {
      message = t("Enter your email address");
      isValid = false;
    } else if (!validator.isEmail(email.value)) {
      message = t("Invalid email address");
      isValid = false;
    }
    setEmail({ ...email, isValid, message });
    return isValid;
  };

  const passwordIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(password.value)) {
      isValid = false;
      message = t("Enter your password");
    }
    setPassword({ ...password, isValid, message });
    return isValid;
  };

  const signUp = async () => {
    if (isValid()) {
      try {
        const data = {
          email: email.value,
          password: password.value,
        };
        await auth.signUp(data);
        redirect();
      } catch (error) {
        setError(localizedError(error as Error, t));
      }
    }
  };

  const redirect = () => {
    const { search } = location;
    const from = getQuery(search, "from") || "/";
    navigate(`/signup/complete?from=${from}`);
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="main-content content-login">
        <div className="form-login">
          <h1>{t("Create an account")}</h1>
          <form noValidate onSubmit={submit}>
            <div className="field">
              <Input
                invalid={!email.isValid}
                type="email"
                value={email.value}
                placeholder={t("email adress")}
                onChange={updateEmail}
              />
              <span className="invalid-feedback">{email.message}</span>
            </div>
            <div className="field">
              <Input
                invalid={!password.isValid}
                type="password"
                value={password.value}
                placeholder={t("Password")}
                onChange={updatePassword}
              />
              <span className="invalid-feedback">{password.message}</span>
            </div>
            <Consent action={t("By creating an account")} />
            <div className="inline clearfix">
              <Button type="submit" variant="primary" onClick={signUp}>
                {t("Register")}
              </Button>
              <Button type="submit" variant="secondary" onClick={cancel}>
                {t("Cancel")}
              </Button>
            </div>
            {renderError()}
          </form>
          <div className="register">
            <Trans i18nKey="Already have an account? Sign in here.">
              Already have an account? Sign in{" "}
              <Link
                style={{ color: "#FB5607" }}
                className="d-inline"
                to="/signin"
              >
                here
              </Link>
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
