import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export const COOKIE_CONSENT = "cookie-consent";

interface Props {}
const Cookies = (props: Props) => {
  const isCookieDefined = () =>
    typeof getCookieConsentValue(COOKIE_CONSENT) === "undefined";

  const { t } = useTranslation();

  const [show, setShow] = useState(isCookieDefined());

  useEffect(() => {
    setShow(isCookieDefined());
  });

  const grantNonEssential = () => {
    setShow(false);
    gtag("consent", "update", {
      analytics_storage: "granted",
    });
  };

  const denyNonEssential = () => {
    setShow(false);
    gtag("consent", "update", {
      analytics_storage: "denied",
    });
  };

  return (
    <CookieConsent
      visible={show ? "show" : "hidden"}
      location="bottom"
      buttonText={t("Accept all")}
      declineButtonText={t("Reject non-essential")}
      cookieName={COOKIE_CONSENT}
      enableDeclineButton
      debug={false}
      onAccept={grantNonEssential}
      onDecline={denyNonEssential}
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b" }}
      overlay={false}
      expires={365}
    >
      <Trans>
        This website uses cookies to ensure you get the best experience on our
        website. Read our{" "}
        <Link className="d-inline text-white" to="privacy">
          <u>privacy policy</u>
        </Link>
        .
      </Trans>
    </CookieConsent>
  );
};

export default Cookies;
