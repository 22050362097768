import React, { ChangeEvent, useState } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { Country } from "../../store";
import NumberInput from "../general/numberinput";

const CURRENCIES = ["eur", "gbp"];

interface Props {
  country?: Country;
}
const Settings = (props: Props) => {
  const { country } = props;
  const [updates, setUpdates] = useState<any>({});
  const { t } = useTranslation();

  const updateCurrency = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUpdates({ ...updates, currency: value });
  };

  const updateFundMin = (value?: number) => {
    setUpdates({
      ...updates,
      fund: {
        ...updates?.fund,
        min: value ? value * 100 : value,
      },
    });
  };

  const updateFundMax = (value?: number) => {
    setUpdates({
      ...updates,
      fund: {
        ...updates?.fund,
        max: value ? value * 100 : value,
      },
    });
  };

  const updateFundAdd = (value?: number) => {
    setUpdates({
      ...updates,
      fund: {
        ...updates?.fund,
        add: value ? value * 100 : value,
      },
    });
  };

  const updateFeeType =
    (key: "default" | "min" | "max" | "add", type: string) => () => {
      const value = valueForFee(key) * (type === "amount" ? 100 : 1);
      setUpdates({
        ...updates,
        fee: {
          ...updates?.fee,
          [key]: {
            value,
            type,
          },
        },
      });
    };

  const updateFeeValue =
    (key: "default" | "min" | "max" | "add") => (value?: number) => {
      const fee = updates?.fee || country?.applicationFee || {};
      const type = fee[key]?.type || "amount";
      setUpdates({
        ...updates,
        fee: {
          ...fee,
          [key]: {
            type,
            value: value && type === "amount" ? value * 100 : value,
          },
        },
      });
    };

  const updateTax = (key: "fund" | "fee") => (value?: number) => {
    const tax = (value || 0) / 100;
    setUpdates({
      ...updates,
      [key]: {
        ...updates[key],
        tax,
      },
    });
  };

  const valueForFund = (key: "min" | "max" | "add") => {
    if (Object.keys(updates?.fund || {}).indexOf(key) >= 0) {
      if (updates?.fund[key] === null) return undefined;
      return updates?.fund[key] / 100;
    }
    return country?.data?.fund && country?.data?.fund[key]
      ? (country?.data?.fund[key] || 0) / 100
      : undefined;
  };

  const prefixForCurrency = (currency: string) => {
    switch (currency) {
      case "eur":
        return "€";
      case "gbp":
        return "£";
    }
    return "";
  };

  const typeForFee = (key: "default" | "min" | "max" | "add") => {
    const fee = (updates?.fee || country?.applicationFee || {})[key];
    if (fee?.type === "percentage") return "%";
    else return prefixForCurrency(updates.currency || country?.currency);
  };

  const valueForFee = (key: "default" | "min" | "max" | "add") => {
    let fee;
    if (Object.keys(updates?.fee || {}).indexOf(key) >= 0) {
      fee = updates?.fee[key];
    } else {
      fee = country?.applicationFee[key];
    }
    if (fee?.value === undefined || fee?.value === null) return undefined;
    if (fee?.type === "percentage") return fee?.value;
    else return (fee?.value || 0) / 100;
  };

  const decimalsForFee = (key: "default" | "min" | "max" | "add") => {
    const fee = (updates?.fee || country?.applicationFee || {})[key];
    if (fee?.type === "percentage") return 1;
    else return 2;
  };

  const save = async () => {
    const data = {
      ...updates,
      fund: {
        ...country?.data.fund,
        ...updates.fund,
      },
      fee: {
        default: {
          ...country?.data.fee?.default,
          ...updates.fee?.default,
        },
        min: {
          ...country?.data.fee?.min,
          ...updates.fee?.min,
        },
        max: {
          ...country?.data.fee?.max,
          ...updates.fee?.max,
        },
        tax: updates.fee?.tax || country?.data.fee?.tax,
      },
    };
    if (data.fund?.max === undefined) delete data.fund?.max;
    if (data.fund?.min === undefined) delete data.fund?.min;
    if (updates.fee?.default && updates.fee?.default?.value === undefined)
      delete data.fee?.default;
    if (updates.fee?.min && updates.fee?.min?.value === undefined)
      delete data.fee?.min;
    if (updates.fee?.max && updates.fee?.max?.value === undefined)
      delete data.fee?.max;
    await country?.update(data);
    setUpdates({});
  };

  const valueForTax = (key: "fund" | "fee") => {
    const value =
      key === "fund"
        ? updates?.fund?.tax || country?.data?.fund?.tax
        : updates?.fee?.tax || country?.data?.fee?.tax;
    return value ? value * 100 : undefined;
  };

  const renderCurrency = () => {
    return (
      <Form.Group controlId="currency">
        <Form.Label>
          <Trans>Currency</Trans>
        </Form.Label>
        <Form.Control
          as="select"
          onChange={updateCurrency}
          value={updates.currency || country?.currency}
        >
          {CURRENCIES.map((currency) => (
            <option value={currency} key={currency}>
              {currency.toUpperCase()} ({prefixForCurrency(currency)})
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  };

  const renderFund = () => {
    const prepend = prefixForCurrency(updates.currency || country?.currency);
    return (
      <Form.Group controlId="fund">
        <Form.Label>
          <Trans>Fund</Trans>
        </Form.Label>
        <Row>
          <Col>
            <NumberInput
              value={valueForFund("min")}
              placeholder={t("No minimum")}
              prepend={prepend}
              onChange={updateFundMin}
              decimals={2}
            />
            <Form.Text className="text-muted">
              <Trans>Minimum</Trans>
            </Form.Text>
          </Col>
          <Col>
            {" "}
            <NumberInput
              value={valueForFund("max")}
              placeholder={t("No maximum")}
              prepend={prepend}
              onChange={updateFundMax}
              decimals={2}
            />
            <Form.Text className="text-muted">
              <Trans>Maximum</Trans>
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  const renderFee = () => {
    return (
      <Form.Group controlId="fee">
        <Form.Label>
          <Trans>Fee</Trans>
        </Form.Label>
        <InputGroup>
          <Row>
            <Col>
              <NumberInput
                value={valueForFee("default")}
                placeholder={t("No default")}
                onChange={updateFeeValue("default")}
                decimals={decimalsForFee("default")}
                append={
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-secondary"
                    title={typeForFee("default")}
                    id="fee-type"
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("default", "amount")}
                    >
                      <Trans>Amount</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("default", "percentage")}
                    >
                      <Trans>Percentage</Trans>
                    </Dropdown.Item>
                  </DropdownButton>
                }
              />
              <Form.Text className="text-muted">
                <Trans>Default</Trans>
              </Form.Text>
            </Col>
            <Col>
              <NumberInput
                value={valueForFee("min")}
                placeholder={t("No minimum")}
                onChange={updateFeeValue("min")}
                decimals={decimalsForFee("min")}
                append={
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-secondary"
                    title={typeForFee("min")}
                    id="fee-type"
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("min", "amount")}
                    >
                      <Trans>Amount</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("min", "percentage")}
                    >
                      <Trans>Percentage</Trans>
                    </Dropdown.Item>
                  </DropdownButton>
                }
              />
              <Form.Text className="text-muted">
                <Trans>Minimum</Trans>
              </Form.Text>
            </Col>
            <Col>
              <NumberInput
                value={valueForFee("max")}
                placeholder={t("No maximum")}
                onChange={updateFeeValue("max")}
                decimals={decimalsForFee("max")}
                append={
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-secondary"
                    title={typeForFee("max")}
                    id="fee-type"
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("max", "amount")}
                    >
                      <Trans>Amount</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={updateFeeType("max", "percentage")}
                    >
                      <Trans>Percentage</Trans>
                    </Dropdown.Item>
                  </DropdownButton>
                }
              />
              <Form.Text className="text-muted">
                <Trans>Maximum</Trans>
              </Form.Text>
            </Col>
          </Row>
        </InputGroup>
      </Form.Group>
    );
  };

  const renderFixed = () => {
    const prepend = prefixForCurrency(updates.currency || country?.currency);
    return (
      <Form.Group controlId="fund">
        <Row>
          <Col>
            <NumberInput
              value={valueForFund("add")}
              placeholder={t("No fixed")}
              prepend={prepend}
              onChange={updateFundAdd}
              decimals={2}
            />
            <Form.Text className="text-muted">
              <Trans>Fixed amount</Trans>
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  const renderTax = () => {
    return (
      <Form.Group controlId="fund">
        <Form.Label>
          <Trans>VAT</Trans>
        </Form.Label>
        <Row>
          <Col>
            <NumberInput
              value={valueForTax("fund")}
              placeholder={t("No VAT")}
              append="%"
              onChange={updateTax("fund")}
              decimals={2}
            />
            <Form.Text className="text-muted">
              <Trans>Fund</Trans>
            </Form.Text>
          </Col>
          <Col>
            <NumberInput
              value={valueForTax("fee")}
              placeholder={t("No VAT")}
              append="%"
              onChange={updateTax("fee")}
              decimals={2}
            />
            <Form.Text className="text-muted">
              <Trans>Fee</Trans>
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  const renderButtons = () => (
    <div className="form-actions">
      <Button variant="secondary" onClick={() => setUpdates({})}>
        <Trans>Cancel</Trans>
      </Button>
      <Button variant="primary" onClick={save}>
        <Trans>Save</Trans>
      </Button>
    </div>
  );
  return (
    <Form className="form-center">
      {renderCurrency()}
      {renderFund()}
      {renderFee()}
      {renderFixed()}
      {renderTax()}
      {renderButtons()}
    </Form>
  );
};

export default Settings;
