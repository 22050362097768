import React from "react";
import { Outlet } from "react-router-dom";

import ScrollUp from "../components/general/scrollup";
import ProfileSettings from "../components/profile";
import ProfileHeader from "../components/profile/header";

const Profile = () => {
  return (
    <ScrollUp>
      <main id="main" className="site-main account-profile">
        <ProfileHeader />
        <ProfileSettings>
          <Outlet />
        </ProfileSettings>
      </main>
    </ScrollUp>
  );
};

export default Profile;
