import classNames from "classnames";
import first from "lodash/first";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { useStore } from "../../store";
import Avatar from "../general/avatar";
import Input from "../general/input";

interface Value<T> {
  value: T;
  isValid: boolean;
  message: string;
}

interface Image extends File {
  preview: string;
}

interface Props {}
const Header = (props: Props) => {
  const { auth } = useStore();
  const { profile } = auth;
  const [name, setName] = useState({
    value: profile?.displayName || "",
    isValid: true,
    message: "",
  });
  const [image, setImage] = useState<Value<string | undefined>>({
    value: profile?.photoURL,
    isValid: true,
    message: "",
  });
  const [error, setError] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [upload, setUpload] = useState<Image | undefined>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const file = first(acceptedFiles);
    setUpload(
      file
        ? (Object.assign(file, { preview: URL.createObjectURL(file) }) as Image)
        : undefined,
    );
  }, [acceptedFiles]);

  const toggleEdit = () => {
    removeImage();
    setEdit(!edit);
  };

  const updateName = (value: string) => {
    setName({ ...name, value });
  };

  const removeImage = () => {
    setUpload(undefined);
    setImage({ ...image, value: undefined });
  };

  const isValid = () => {
    const nameValid = nameIsValid();
    return nameValid;
  };

  const nameIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(name.value)) {
      isValid = false;
      message = t("Enter your username");
    }
    setName({ ...name, isValid, message });
    return isValid;
  };

  const save = async () => {
    if (isValid()) {
      try {
        const data = {
          displayName: name.value,
        };
        if (upload) await profile?.updatePhotoURL({ upload });
        await profile?.update(data);
        setEdit(false);
      } catch (error) {
        setError(localizedError(error as Error, t));
      }
    }
  };

  const renderUpload = () => {
    const logoClass = classNames("file-upload upload-bg", {
      "is-invalid": !image.isValid,
    });

    const preview = upload?.preview || image.value;
    if (preview) {
      return (
        <div className="upload">
          <div className={logoClass}>
            <div className="upload-preview">
              <img src={preview} alt={t("Example")} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="upload">
          <Avatar user={profile} size={150} />
          <div className={logoClass}>
            <div {...getRootProps({ className: "dropzone choicefile1" })}>
              <input {...getInputProps()} />
              <i className="fas fa-cloud-upload-alt" aria-hidden="true" />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderAvatar = () => {
    return (
      <div className="photo">
        {edit ? renderUpload() : <Avatar user={profile} size={150} />}
      </div>
    );
  };

  const renderName = () => {
    if (edit) {
      return (
        <Input
          invalid={!name.isValid}
          type="text"
          value={name.value}
          placeholder={t("Username")}
          onChange={updateName}
        />
      );
    }
    return <h1>{profile?.displayName}</h1>;
  };

  const renderButtons = () => {
    if (edit) {
      return (
        <>
          <Button size="sm" variant="outline-primary" onClick={toggleEdit}>
            {t("Cancel")}
          </Button>
          <Button size="sm" variant="primary" onClick={save}>
            {t("Save")}
          </Button>
        </>
      );
    }
    return (
      <Button size="sm" variant="outline-primary" onClick={toggleEdit}>
        {t("Edit")}
      </Button>
    );
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  return (
    <div className="header">
      {renderAvatar()}
      <div>
        {renderName()}
        <p>{profile?.email}</p>
        {renderButtons()}
        {renderError()}
      </div>
    </div>
  );
};

export default observer(Header);
