import firebase from "firebase/compat/app";
import { Collection } from "firestorter";
import { v4 as uuid } from "uuid";

import { League } from ".";
import Campaign, { CampaignData } from "./campaign";
import { Data } from "../general/data";
import Document from "../general/document";

export interface ClubData extends Data {
  title: string;
  logo?: string;
  image?: string;
}

export interface Query {
  where?: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.WhereFilterOp,
    any,
  ];
  orderBy?: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.OrderByDirection,
  ];
  limit?: number;
}

export interface CampaignAddData
  extends Omit<Omit<Omit<CampaignData, "createdAt">, "updatedAt">, "endAt"> {
  endAt: Date;
  uploads?: Blob[];
  id?: string;
}

class Club extends Document<ClubData> {
  private _league?: League;
  private _campaigns?: Collection<Campaign>;

  get link() {
    const path = this.path as string | undefined;
    return path?.replace("country", "") || "";
  }

  get title() {
    return this.data.title;
  }

  get logo() {
    return this.data.logo;
  }
  get image() {
    return this.data.image;
  }

  get league() {
    if (!this._league && this.ref?.parent?.parent) {
      this._league = new League(this.ref?.parent?.parent, {}, this.store);
    }
    return this._league;
  }

  get campaigns() {
    if (!this._campaigns) {
      this._campaigns = new Collection(() => `${this.path}/campaign`, {
        createDocument: (source, options) =>
          new Campaign(source, options, this.store),
        query: (ref: firebase.firestore.CollectionReference) =>
          ref.where("published", "==", true),
      });
    }
    return this._campaigns;
  }

  get adminCampaigns() {
    if (!this._campaigns) {
      this._campaigns = new Collection(() => `${this.path}/campaign`, {
        createDocument: (source, options) =>
          new Campaign(source, options, this.store),
      });
    }
    return this._campaigns;
  }

  filteredCampaigns = (query: Query) => {
    const { where, orderBy, limit } = query || {};
    return new Collection(() => `${this.path}/campaign`, {
      createDocument: (source, options) =>
        new Campaign(source, options, this.store),
      query: (ref: firebase.firestore.CollectionReference) => {
        let newRef: firebase.firestore.Query<firebase.firestore.DocumentData> =
          ref.where("published", "==", true);
        if (where) {
          newRef = newRef.where(where[0], where[1], where[2]);
        }
        if (orderBy) {
          newRef = newRef.orderBy(orderBy[0], orderBy[1]);
        }
        if (limit) {
          newRef = newRef.limit(limit);
        }
        return newRef;
      },
    });
  };

  addCampaign = async (data: CampaignAddData) => {
    // const db = firebase.firestore();
    // const campaignsRef = db
    //   .collection("country")
    //   .doc("nl")
    //   .collection("campaigns");
    // const amateurCampaignsRef = db
    //   .collection("country")
    //   .doc("nl")
    //   .collection("amateurCampaigns");

    const { id, uploads, leagueId, clubId, ...otherData } = data;
    const images = uploads?.length
      ? await Promise.all(
          uploads.map(async (upload) => {
            const snapshot = await firebase
              .storage()
              .ref()
              .child(`${this.path}/campaign/${uuid()}`)
              .put(upload);
            return await snapshot.ref.getDownloadURL();
          }),
        )
      : data.images;
    const campaignId = id || uuid(); // Use existing ID or generate a new one
    const saveData = {
      ...otherData,
      leagueId,
      clubId,
      images: images || firebase.firestore.FieldValue.delete(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    if (id) {
      try {
        // Update campaign inside nested league and clubs
        const campaign = this.ref.collection("campaign").doc(campaignId);
        await campaign.set(
          {
            ...otherData,
            images: images || firebase.firestore.FieldValue.delete(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );

        // // Update campaign in top collection
        // const campaignRef = campaignsRef?.doc(campaignId);
        // if ((await campaignRef.get()).exists) {
        //   if (!(await campaignRef.get()).exists)
        //     throw new Error("Campaign not found");
        //   await campaignRef.set(saveData, { merge: true });
        // }

        // // Update campaign in amateur campaigns collection if applicable
        // if (leagueId === "aOauKTjqnl94q7fubLY0") {
        //   const campaignRefAmateur = amateurCampaignsRef.doc(campaignId);
        //   if (!(await campaignRefAmateur.get()).exists)
        //     throw new Error("Campaign not found");
        //   await campaignRefAmateur.set(saveData, { merge: true });
        // }
        return new Campaign(campaign, {}, this.store);
      } catch (error) {
        console.error("Error updating campaign:", error);
        throw new Error("Failed to update campaign");
      }
    } else {
      try {
        // Add new campaign in nested collection
        const campaign = this.ref.collection("campaign").doc(campaignId);
        await campaign.set(
          {
            ...saveData,
            popularity: 0,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
        // // Add campaign in top collection
        // await campaignsRef.doc(campaignId).set({
        //   ...saveData,
        //   popularity: 0,
        //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // });
        // // Add campaign in amateur campaigns collection if applicable
        // if (leagueId === "aOauKTjqnl94q7fubLY0") {
        //   await amateurCampaignsRef.doc(campaignId).set({
        //     ...saveData,
        //     popularity: 0,
        //     cashed: otherData.cashed || false,
        //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        //   });
        // }
        return new Campaign(campaign, {}, this.store);
      } catch (error) {
        console.error("Error adding new campaign:", error);
        throw new Error("Failed to add new campaign");
      }
    }
  };
}

export default Club;
