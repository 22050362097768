import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { Carousel } from "react-bootstrap";
// import { useMediaQuery } from "react-responsive";

import { useStore } from "../../store";

interface Props {}
const InfoWork = (props: Props) => {
  const { data } = useStore();
  const { t } = useTranslation();

  // const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  // const ITEMS = [
  //   {
  //     icon: <i className="fa fa-lightbulb" aria-hidden="true" />,
  //     text: t("Fan initiates a support action for the club"),
  //     button: (
  //       <Link to="form" className="btn btn-primary">
  //         {t("Start an action")}
  //       </Link>
  //     ),
  //   },
  //   {
  //     icon: <i className="fa fa-money-bill-wave" aria-hidden="true" />,
  //     text: t("Fans donate money for a support action"),
  //     button: (
  //       <Link to="explore" className="btn btn-primary">
  //         {t("Discover Support actions")}
  //       </Link>
  //     ),
  //   },
  //   {
  //     icon: <i className="fa fa-flag" aria-hidden="true" />,
  //     text: t("Club cashes donations and performs the support action"),
  //     button: (
  //       <Link to="explore#cashed" className="btn btn-primary">
  //         {t("See cashed actions")}
  //       </Link>
  //     ),
  //   },
  // ];

  // const renderItems = () => {
  //   if (isDesktop) {
  //     return (
  //       <div className="row">
  //         {ITEMS.map((item) => (
  //           <div className="col-lg-4" key={item.text}>
  //             <div className="item-work">
  //               <div className="item-icon">{item.icon}</div>
  //               <div className="item-content">
  //                 <div className="item-desc">
  //                   <p>{item.text}</p>
  //                 </div>
  //               </div>
  //               {item.button}
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return (
  //     <Carousel
  //       prevIcon={<i className="fas fa-angle-left carousel-indicator" />}
  //       prevLabel={""}
  //       nextIcon={<i className="fas fa-angle-right carousel-indicator" />}
  //       nextLabel={""}
  //     >
  //       {ITEMS.map((item) => (
  //         <Carousel.Item key={item.text}>
  //           <div className="item-work">
  //             <div className="item-icon">{item.icon}</div>
  //             <div className="item-content">
  //               <div className="item-desc">
  //                 <p>{item.text}</p>
  //               </div>
  //             </div>
  //             {item.button}
  //           </div>
  //         </Carousel.Item>
  //       ))}
  //     </Carousel>
  //   );
  // };

  return (
    <div className="how-it-work">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 promise">
            <p className="promise-title">{t("Clubmeister's promise")}:</p>
            <p className="promise-text">
              {t(
                "You will get your money back if the club doesn't cash in the donations",
              )}
            </p>
            <Link
              to={`/${data.country?.id}/policy/donation`}
              className="promise-link"
            >
              {t("See our Donation conditions")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoWork;
