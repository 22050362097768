import firebase from "firebase/compat/app";
import { Collection } from "firestorter";

import Club from "./club";
import Comment, { CommentData } from "./comment";
import { FundData } from "./fund";
import Profile from "../auth/profile";
import { Data } from "../general/data";
import Document from "../general/document";

export interface LocalizedString {
  [language: string]: string;
}

export interface CampaignData extends Data {
  title: LocalizedString;
  description: LocalizedString;
  cashed: boolean;
  relation?: string;
  images?: string[];
  userId: string;
  goal?: number;
  raised?: number;
  fundings?: number;
  likes?: string[];
  popularity?: number;
  published?: boolean;
  rejected?: boolean;
  publishedFlag?: boolean;
  createdAt: firebase.firestore.Timestamp;
  endAt: firebase.firestore.Timestamp;
  videoUrl: LocalizedString;
  clubId: string;
  leagueId: string;
}

type CommentAddData = Omit<
  Omit<Omit<CommentData, "createdAt">, "updatedAt">,
  "blocked"
>;

class Campaign extends Document<CampaignData> {
  private _club?: Club;
  private _user?: Profile;
  private _comments?: Collection<Comment>;

  get link() {
    const path = this.path as string | undefined;
    return path?.replace("country", "") || "";
  }

  get comments() {
    if (!this._comments) {
      this._comments = new Collection(() => `${this.path}/comment`, {
        query: (ref: firebase.firestore.CollectionReference) =>
          ref.where("blocked", "==", false).orderBy("createdAt", "desc"),
        createDocument: (source, options) =>
          new Comment(source, options, this.store),
      });
    }
    return this._comments;
  }
  get club() {
    if (!this._club && this.ref?.parent?.parent) {
      this._club = new Club(this.ref?.parent?.parent, {}, this.store);
    }
    return this._club;
  }
  get clubId() {
    return this.data.clubId;
  }
  get leagueId() {
    return this.data.leagueId;
  }
  get title() {
    return this.localizedText(this.data.title);
  }
  get description() {
    return this.localizedText(this.data.description);
  }
  get relation() {
    return this.data.relation;
  }
  get image() {
    return this.data.images?.length ? this.data.images[0] : undefined;
  }
  get images() {
    return this.data.images;
  }
  get createdAt() {
    return this.data.createdAt?.toDate();
  }
  get endAt() {
    return this.data.endAt?.toDate();
  }
  get videoUrl() {
    return this.localizedText(this.data.videoUrl);
  }
  get fundings() {
    return this.data.fundings || 0;
  }
  get raised() {
    return this.data.raised || 0;
  }
  get goal() {
    return this.data.goal || 0;
  }
  get popularity() {
    return this.data.popularity || 0;
  }
  get user() {
    if (this.data.userId && this.data.userId !== this._user?.id) {
      this._user = new Profile(`profile/${this.data.userId}`, {}, this.store);
    }
    return this._user;
  }

  get likes() {
    return (this.data.likes || []).length;
  }

  get didLike() {
    const { userId } = this.store.auth;
    return userId && (this.data.likes || []).indexOf(userId) >= 0;
  }

  get isPublished() {
    return !!this.data.published;
  }

  get isRejected() {
    return !!this.data.rejected;
  }

  get isPublishedFlag() {
    return !!this.data.publishedFlag;
  }

  publish = async () => {
    await this.ref?.update({
      published: true,
    });
    if (!this.isPublishedFlag) {
      await firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("publishCampaign")({
        countryId: this.store.data.country?.id,
        leagueId: this.club?.league?.id,
        clubId: this.club?.id,
        campaignId: this.id,
        email: this._user?.email,
        userName: this._user?.displayName,
        published: !!this.data.published,
      });
    }
    await this.ref?.update({
      publishedFlag: true,
    });
  };

  newCollectionPublish = async () => {
    if ((await this.ref.get()).exists) {
      await this.ref?.update({
        published: true,
      });
    }
  };

  unpublish = async () => {
    await this.ref?.update({
      published: false,
    });
  };

  reject = async (
    reason: string,
    removeAndSend: boolean,
    removeOnly: boolean,
  ) => {
    await this.ref?.update({
      rejected: true,
    });
    if (reason) {
      return await firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("rejectCampaign")({
        countryId: this.store.data.country?.id,
        leagueId: this.club?.league?.id,
        clubId: this.club?.id,
        campaignId: this.id,
        email: this._user?.email,
        userName: this._user?.displayName,
        reason,
        removeAndSend,
        removeOnly,
      });
    }
  };

  addLike = async () => {
    return await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("likeCampaign")({
      countryId: this.store.data.country?.id,
      leagueId: this.club?.league?.id,
      clubId: this.club?.id,
      campaignId: this.id,
    });
  };

  addFund = async (data: FundData) => {
    const { amount, currency } = data;
    const response = await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("fundStart")({
      countryId: this.store.data.country?.id,
      leagueId: this.club?.league?.id,
      clubId: this.club?.id,
      campaignId: this.id,
      amount,
      currency,
    });
    return response?.data;
  };

  addComment = async (data: CommentAddData) => {
    await this.ref?.collection("comment").add({
      ...data,
      blocked: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  private localizedText = (text?: LocalizedString) => {
    if (!text) return "";
    const localized = text[this.store.i18n.language];
    if (localized) return localized;
    const englishText = text["en"];
    if (englishText) return englishText;
    return text[Object.keys(text)[0]];
  };
}

export default Campaign;
