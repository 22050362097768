import firebase from "firebase/compat/app";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Link, useNavigate, RouteProps, useLocation } from "react-router-dom";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { getQuery } from "../../helpers/tools";
import { NotificationData, UserSegmentData, useStore } from "../../store";
import Input from "../general/input";
import Select from "../general/select";

const Complete = (props: RouteProps) => {
  const { auth } = useStore();
  const { profile, isSignedIn, isLoaded } = auth;
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const location = useLocation();

  const [email, setEmail] = useState({
    value: auth.email ?? "",
    isValid: true,
    message: "",
  });
  const [name, setName] = useState({
    value: auth?.profile?.displayName ?? "",
    isValid: true,
    message: "",
  });
  const [segment, setSegment] = useState({
    value: {} as UserSegmentData,
    isValid: true,
    message: "",
  });
  const [notifications, setNotifications] = useState<NotificationData>(
    profile?.notificationPreferences
      ? profile?.notificationPreferences
      : {
          donations: {
            dateChanged: new Date(),
            accepted: true,
          },
          marketing: {
            dateChanged: new Date(),
            accepted: false,
          },
        },
  );

  const [userAgreement, setUserAgreement] = useState(false);

  const { t } = useTranslation();

  const settings = [
    // {
    //   id: "donations",
    //   title: t("Donations"),
    //   description: t(
    //     "Receive a message if the club cashes in your donation or if you get your money back"
    //   ),
    // },
    {
      id: "marketing",
      title: t("Newsletter"),
      description: t("Receive news about the platform"),
    },
  ];

  useEffect(() => {
    if (auth.email) {
      updateEmail(auth.email);
    }
    if (auth?.profile?.displayName) {
      updateName(auth?.profile?.displayName);
    }
  }, [isLoaded]);

  const updateEmail = (value: string) => {
    setEmail({ ...email, value });
  };

  const updateName = (value: string) => {
    setName({ ...name, value });
  };

  const isValid = () => {
    return emailIsValid() && nameIsValid();
  };

  const nameIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(name.value)) {
      isValid = false;
      message = t("Enter your username");
    }
    setName({ ...name, isValid, message });
    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(email.value)) {
      message = t("Enter your email address");
      isValid = false;
    } else if (!validator.isEmail(email.value)) {
      message = t("Invalid email address");
      isValid = false;
    }
    setEmail({ ...email, isValid, message });
    return isValid;
  };

  const update = async () => {
    if (isValid()) {
      try {
        await firebase.auth().currentUser?.updateEmail(email.value);
        await profile?.update({
          notifications,
          userAgreement: {
            dateAccepted: new Date(),
          },
          displayName: name.value,
          segment: segment.value,
        });
        redirect();
      } catch (error) {
        setError(localizedError(error as Error, t));
      }
    }
  };

  const updateNotifications = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const value = e.target.checked;
    setNotifications({
      ...notifications,
      [id]: {
        accepted: value,
        dateChanged: new Date(),
      },
    });
  };

  const updateFrequency = (value: string) => {
    const segmentValue = { ...segment.value };
    segmentValue.frequency = value;
    setSegment({ ...segment, value: segmentValue });
  };

  const updateChannel = (value: string) => {
    const segmentValue = { ...segment.value };
    if (!segmentValue.channel) segmentValue.channel = [];
    segmentValue.channel = segmentValue.channel.includes(value)
      ? segmentValue.channel.filter((i) => i !== value)
      : [...segmentValue.channel, value];
    setSegment({ ...segment, value: segmentValue });
  };

  const redirect = () => {
    const { search } = location;
    const from = getQuery(search, "from") || "/";
    navigate(from);
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderUserName = () => {
    return (
      <Input
        invalid={!name.isValid}
        type="name"
        value={name.value}
        placeholder={t("Username")}
        onChange={updateName}
      />
    );
  };

  const renderEmail = () => {
    if (!auth.email) {
      return (
        <Input
          invalid={!email.isValid}
          type="email"
          value={email.value}
          placeholder={t("email adress")}
          onChange={updateEmail}
        />
      );
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  if (!isSignedIn) {
    navigate(-1);
  }

  const channelOptions = [
    { value: "season", label: t("In stadion with seasoncard") },
    { value: "ticket", label: t("In stadion with match ticket") },
    { value: "tv", label: t("On television") },
    { value: "social", label: t("On soclial media") },
    { value: "supporter", label: t("On supporter platform(s)") },
  ];

  return (
    <>
      {isLoaded ? (
        <div className="container">
          <div className="main-content content-login">
            <div className="form-login">
              <form noValidate onSubmit={submit}>
                <h2 className="mb-0">{t("Choose a username:")}</h2>
                <small>
                  <i className="d-block mb-3">
                    {t("Username will be publicly visible.")}
                  </i>
                </small>
                <div className="field">
                  {renderUserName()}
                  <span className="invalid-feedback">{name.message}</span>
                </div>
                <div className="d-none">
                  <h2>{t("How do you follow your club?")}</h2>
                  <div className="field">
                    {channelOptions.map((option) => (
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          updateChannel(e.currentTarget.value);
                          // updateFrequency("channel", e.currentTarget.value);
                        }}
                        name="channel"
                        value={option.value}
                        id={option.value}
                        label={option.label}
                      />
                    ))}
                  </div>
                  <h2>{t("How often do you follow your club?")}</h2>
                  <div className="field field-select">
                    <Select
                      onChange={(value) => updateFrequency(value)}
                      value={segment.value?.frequency}
                    >
                      <option key="empty" value="" />
                      <option key="multiday" value="multiday">
                        {t("Several times per day")}
                      </option>
                      <option key="day" value="day">
                        {t("Every day")}
                      </option>
                      <option key="multiweek" value="multiweek">
                        {t("Several times per week")}
                      </option>
                      <option key="week" value="week">
                        {t("Every week")}
                      </option>
                      <option key="multimonth" value="multimonth">
                        {t("Several times per month")}
                      </option>
                      <option key="month" value="month">
                        {t("Every month")}
                      </option>
                      <option key="multiyear" value="multiyear">
                        {t("Several times per year")}
                      </option>
                      <option key="year" value="year">
                        {t("Every year")}
                      </option>
                    </Select>
                  </div>
                </div>
                <h2 className="mt-2">{t("How can we keep you up to date?")}</h2>
                <div className="field">
                  {renderEmail()}
                  <span className="invalid-feedback">{email.message}</span>
                </div>
                <div className="field">
                  <div className="notification-preferences">
                    <Form.Group controlId="formBasicCheckbox">
                      {settings.map((setting, i) => (
                        <Form.Check
                          key={setting.id}
                          onChange={updateNotifications}
                          className="mb-2"
                          defaultChecked={notifications[setting.id].accepted}
                          id={setting.id}
                          type="checkbox"
                          label={
                            <>
                              <span className="title">{setting.title}</span>
                              <br />
                              <span className="description text-muted">
                                {setting.description}
                              </span>
                            </>
                          }
                        />
                      ))}
                    </Form.Group>
                  </div>
                </div>
                <small className="text-muted">
                  {/* {t(
                    "You can always change these settings later under your profile."
                  )} */}
                </small>
                <hr />
                <Form.Check
                  onChange={() => setUserAgreement(!userAgreement)}
                  className="my-4"
                  defaultChecked={false}
                  id={"user_agreement"}
                  type="checkbox"
                  label={
                    <Trans i18nKey="user_agreement_checkbox">
                      <span className="description text-muted">
                        Ik accepteer de{" "}
                        <Link className="d-inline" to="/nl/policy/terms">
                          Algemenevoorwarden
                        </Link>{" "}
                        en het{" "}
                        <Link className="d-inline" to="/nl/policy/privacy">
                          Privacy beleid
                        </Link>
                        .
                      </span>
                    </Trans>
                  }
                />
                <div className="inline clearfix">
                  <Button
                    type="submit"
                    disabled={!userAgreement}
                    variant="primary"
                    onClick={update}
                  >
                    {t("Save")}
                  </Button>
                </div>
                {renderError()}
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default observer(Complete);
