type LogLevel = "debug" | "info" | "warn" | "error" | "none";

export class Logger {
  static enableLevel = (level: "debug" | "info" | "warn" | "error") => {
    console[level] = console.log; // eslint-disable-line no-console
  };

  static disableLevel = (level: "debug" | "info" | "warn" | "error") => {
    console[level] = function () {}; // eslint-disable-line no-console
  };

  static setLevel = (level: LogLevel) => {
    switch (level) {
      case "debug":
        Logger.enableLevel("debug");
        Logger.enableLevel("info");
        Logger.enableLevel("warn");
        Logger.enableLevel("error");
        break;
      case "info":
        Logger.disableLevel("debug");
        Logger.enableLevel("info");
        Logger.enableLevel("warn");
        Logger.enableLevel("error");
        break;
      case "warn":
        Logger.disableLevel("debug");
        Logger.disableLevel("info");
        Logger.enableLevel("warn");
        Logger.enableLevel("error");
        break;
      case "error":
        Logger.disableLevel("debug");
        Logger.disableLevel("info");
        Logger.disableLevel("warn");
        Logger.enableLevel("error");
        break;
      case "none":
        Logger.disableLevel("debug");
        Logger.disableLevel("info");
        Logger.disableLevel("warn");
        Logger.disableLevel("error");
        break;
      default:
        throw Error(`Invalid log level ${level}`);
    }
  };
}
