import classNames from "classnames";
import { InputGroup } from "react-bootstrap";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useStore } from "../../store";

interface Props extends ReactDatePickerProps {
  invalid?: boolean;
  prepend?: string;
  append?: string;
}
const DateInput = (props: Props) => {
  const { invalid, className, prepend, append, ...otherProps } = props;
  const inputClass = classNames(
    "form-control",
    {
      "is-invalid": invalid,
    },
    className,
  );
  const { i18n } = useStore();

  return (
    <InputGroup>
      {prepend && (
        <InputGroup.Prepend>
          <InputGroup.Text>{prepend}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      <DatePicker
        className={inputClass}
        {...otherProps}
        dateFormat="PPP"
        locale={i18n.locale}
      />
      {append && (
        <InputGroup.Append>
          <InputGroup.Text>{append}</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};

export default DateInput;
