import { observer } from "mobx-react-lite";
import React from "react";

import AdminSettings from "../../components/admin/settings";
import Preloader from "../../components/general/preloader";
import { useStore } from "../../store";

const Settings = () => {
  const { data } = useStore();
  const { country } = data;
  if (!country?.isLoaded) return <Preloader />;

  return (
    <div className="admin">
      <AdminSettings country={country} />
    </div>
  );
};

export default observer(Settings);
