import { observer } from "mobx-react-lite";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useStore } from "../../store";

const LangageSelector = ({ variant = "link" }) => {
  const { t } = useTranslation();

  const { i18n } = useStore();

  const nameForLanguage = (language: string) => {
    switch (language) {
      case "nl":
        return t("Dutch");
      case "en":
        return t("English");
      default:
        return language;
    }
  };

  return (
    <Dropdown className="language" alignRight>
      <Dropdown.Toggle variant={variant}>
        {i18n.language.toUpperCase()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {i18n.locales.map((locale) => {
          const language = locale.split("-")[0];
          return (
            <Dropdown.Item
              onClick={() => i18n.changeLocale(locale)}
              key={locale}
              active={i18n.language === language}
            >
              {nameForLanguage(language)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default observer(LangageSelector);
