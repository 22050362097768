import React from "react";

import { Profile } from "../../store";

interface Props {
  user?: Profile;
  size?: number;
}
const Avatar = (props: Props) => {
  const { user, size } = props;
  const style = size
    ? {
        width: size,
        height: size,
        borderRadius: size / 2,
      }
    : undefined;
  return user?.photoURL ? (
    <div className="avatar" style={style}>
      <img src={user.photoURL} alt="" />
    </div>
  ) : (
    <div className="avatar btn" style={style}>
      <i className="far fa-user" aria-hidden="true" />
    </div>
  );
};

export default Avatar;
