import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import AdminClubFormAll from "../../../components/admin/club/formAll";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const FormAll = () => {
  const { leagueId, clubId } = useParams();
  const { data } = useStore();
  const { country } = data;
  const league = useMemo(
    () => (leagueId ? country?.league(leagueId) : undefined),
    [leagueId, country],
  );
  const club = useMemo(
    () => (leagueId && clubId ? country?.club(leagueId, clubId) : undefined),
    [leagueId, clubId, country],
  );

  if (league && !league.isLoaded && club && !club.isLoaded)
    return <Preloader />;

  return (
    <div className="admin">
      <AdminClubFormAll league={league} club={club} />
    </div>
  );
};

export default observer(FormAll);
