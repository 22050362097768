import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import Preloader from "../components/general/preloader";
import Status from "../components/status";
import { useStore } from "../store";
import Task from "../store/data/task";

const TaskStatus = () => {
  const { taskId } = useParams();
  const store = useStore();
  const task = useMemo(
    () => new Task(`task/${taskId}`, {}, store),
    [taskId, store],
  );

  if (!task || !task.isLoaded) return <Preloader />;

  return <Status task={task} />;
};

export default observer(TaskStatus);
