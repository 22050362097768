import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";

import AdminFund from "../../components/admin/fund";
import Preloader from "../../components/general/preloader";
import { useStore } from "../../store";

const Fund = () => {
  const { data } = useStore();
  const funds = useAsync(() => {
    return data.admin.allFunds();
  }, []);

  const profiles = useAsync(() => {
    return data.admin.profiles();
  }, []);

  const campaigns = useAsync(() => {
    return data.admin.campaigns();
  }, []);

  if (funds.loading || profiles?.loading || campaigns?.loading)
    return <Preloader />;

  return (
    <div className="admin">
      <AdminFund
        funds={funds.result || []}
        profiles={profiles.result || []}
        campaigns={campaigns.result || []}
        fundPage={false}
      />
    </div>
  );
};

export default observer(Fund);
