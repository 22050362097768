import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="main-content main-404">
        <img src={require("../../assets/images/404.png")} alt="" />
        <h1 className="title">{t("Page not found")}</h1>
      </div>
    </div>
  );
};

export default NotFound;
