import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import CampaignDiscover, { Filter } from "../../components/explore/discover";
import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Explore = () => {
  const { leagueId } = useParams();
  const { data } = useStore();
  const { country } = data;
  const league = useMemo(
    () => (leagueId ? country?.league(leagueId) : undefined),
    [leagueId, country],
  );

  const filter = {
    league,
  } as Filter;

  if (!league || !league.isLoaded) return <Preloader />;
  return (
    <ScrollUp>
      <main id="main" className="site-main">
        <div className="explore-club">
          <GeneralHeader title={league.title} image={league.image} />
          <CampaignDiscover hide={["league"]} filter={filter} />
        </div>
      </main>
    </ScrollUp>
  );
};

export default observer(Explore);
