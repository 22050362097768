import { observer } from "mobx-react-lite";
import React from "react";

import AdminLeague from "../../../components/admin/league";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const League = () => {
  const { data } = useStore();
  const { country } = data;

  if (!country?.leagues?.isLoaded) return <Preloader />;

  return (
    <div className="admin">
      <AdminLeague leagues={country?.leagues?.docs} />
    </div>
  );
};

export default observer(League);
