import React from "react";
import { useAsync } from "react-async-hook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import FormCampaign from "../../components/form/campaign";
import GeneralHeader from "../../components/general/header";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Campaign = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const headerImage = useAsync(data.headerImage, []);

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("Start an action")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="campaign-new">
          <GeneralHeader
            title={t("Build your club")}
            image={headerImage.result}
          />
          <div className="container">
            <div className="form-title">
              <h1 className="title">{t("What do you want to achieve?")}</h1>
            </div>
            <div className="info">
              {t(
                "Here you can describe your idea for a support action. Try to estimate the amount needed to realise the support action and mention it at “target amount donations”. If you don’t know the amountyou can leave this empty. Clubmeister will determine the target amount itself or in cooperation with the club",
              )}
            </div>
          </div>
          <FormCampaign />
        </div>
      </main>
    </ScrollUp>
  );
};

export default Campaign;
