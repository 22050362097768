import React from "react";

import Preloader from "./components/general/preloader";
import "./store";
import { Logger } from "./helpers/logger";
import Router from "./router";

Logger.setLevel(
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "debug"
    : "none",
);

const App = () => {
  return (
    <div>
      <Preloader />
      <Router />
    </div>
  );
};

export default App;
