import Markdown from "markdown-to-jsx";
import React from "react";
import { useAsync } from "react-async-hook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import GeneralHeader from "../../components/general/header";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Terms = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const headerImage = useAsync(data.headerImage, []);

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("Terms and conditions")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="campaign-detail">
          <GeneralHeader image={headerImage.result} />
          <div className="container">
            <div className="main-content terms-of-use">
              <h1 className="title">{t("Terms and conditions")}</h1>
              <div className="terms mt-5">
                <Markdown className="markdown">
                  {t("md_general_terms")}
                </Markdown>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ScrollUp>
  );
};

export default Terms;
