import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useAsync } from "react-async-hook";
import { Button, Alert, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { Campaign, useStore } from "../../../store";
import FundTable from "../fund";

interface Props {
  campaign?: Campaign;
}
const AdminCampaignFund = (props: Props) => {
  const { campaign } = props;
  const { data } = useStore();
  const funds = useAsync(
    async () => campaign && data.admin?.funds(campaign),
    [campaign?.isLoaded],
  );
  const showRefundAll =
    funds?.result &&
    funds?.result.some(
      (fund: any) => fund.isLoaded && !fund.isOpen && !fund.refundStatus,
    );
  const { t } = useTranslation();
  const [loading, setLoading] = useState<false | string>(false);
  const [error, setError] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const campaignRefund = async () => {
    setError("");
    setLoading("*");
    setShowConfirm(false);
    try {
      campaign && (await data.admin.refundCampaign(campaign));
    } catch (error) {
      const { message } = error as Error;
      setError(message);
    }
    setLoading(false);
  };

  const profiles = useAsync(() => {
    return data.admin.profiles();
  }, []);

  const campaigns = useAsync(() => {
    return data.admin.campaigns();
  }, []);

  return (
    <div>
      <div className="head">
        {funds.result && funds.result.length > 0 && showRefundAll && (
          <Button
            onClick={() => setShowConfirm(true)}
            className="btn btn-primary"
            disabled={!!loading}
          >
            {loading ? t("Loading...") : t("Refund all")}
          </Button>
        )}
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Refund all donations</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Trans>
              Are you sure you want to refund all donations? This action cannot
              be reversed.
            </Trans>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={() => setShowConfirm(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button variant="danger" onClick={campaignRefund}>
              <Trans>Refund all</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}
      {typeof funds?.result !== "undefined" && (
        <FundTable
          funds={funds.result}
          profiles={profiles.result || []}
          campaigns={campaigns.result || []}
          fundPage={true}
        />
      )}
    </div>
  );
};

export default observer(AdminCampaignFund);
