import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";

import AdminUser from "../../../components/admin/user";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const User = () => {
  const { data } = useStore();
  const profiles = useAsync(() => {
    return data.admin.profiles();
  }, []);

  if (profiles.loading) return <Preloader />;

  return (
    <div className="admin">
      <AdminUser profiles={profiles.result || []} />
    </div>
  );
};

export default observer(User);
