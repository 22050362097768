import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Admin from "./components/admin/admin";
import Auth from "./components/auth/auth";
import Complete from "./components/auth/complete";
import SignInForgot from "./components/auth/forgot";
import SignInSent from "./components/auth/sent";
import SignIn from "./components/auth/signin";
import SignUp from "./components/auth/signup";
import NotFound from "./components/general/notfound";
import Campaigns from "./components/profile/campaigns";
import Funds from "./components/profile/funds";
import Notifications from "./components/profile/notifications";
import Settings from "./components/profile/settings";
import About from "./pages/about";
import AdminCampaign from "./pages/admin/campaign";
import AdminCampaignForm from "./pages/admin/campaign/form";
import AdminCampaignFund from "./pages/admin/campaign/fund";
import AdminClub from "./pages/admin/club";
import AdminClubForm from "./pages/admin/club/form";
import AdminClubFormAll from "./pages/admin/club/formAll";
import AdminComment from "./pages/admin/comment";
import AdminDashboard from "./pages/admin/dashboard";
import AdminFund from "./pages/admin/fund";
import AdminLeague from "./pages/admin/league";
import AdminLeagueForm from "./pages/admin/league/form";
import AdminSettings from "./pages/admin/settings";
import AdminUser from "./pages/admin/user";
import AdminUserDetails from "./pages/admin/user/details";
import CampaignDetails from "./pages/campaign/details";
import Clubs from "./pages/clubs";
import Contact from "./pages/contact";
import Country from "./pages/country";
import DiscoverEmbed from "./pages/embed/discover";
import ExploreAll from "./pages/explore/all";
import ExploreClub from "./pages/explore/club";
import ExploreLeague from "./pages/explore/league";
import FAQ from "./pages/faq";
import FormNew from "./pages/form/new";
import FormThanks from "./pages/form/thanks";
import FundCheckout from "./pages/fund/checkout";
import FundComplete from "./pages/fund/complete";
import Home from "./pages/home";
import Info from "./pages/info";
import Cookies from "./pages/policy/cookies";
import Privacy from "./pages/policy/privacy";
import Terms from "./pages/policy/terms";
import Profile from "./pages/profile";
import Status from "./pages/status";

const AppRouter = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("Your voice, your support, your club")}
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="/nl" />} />
          <Route path="/:countryId" element={<Country />}>
            <Route element={<NotFound />} />
            <Route index element={<Home />} />
            <Route path="explore" element={<ExploreAll />} />
            <Route
              path="league/:leagueId/explore"
              element={<ExploreLeague />}
            />
            <Route
              path="league/:leagueId/club/:clubId/explore"
              element={<ExploreClub />}
            />
            <Route
              path="league/:leagueId/club/:clubId/campaign/:campaignId"
              element={<CampaignDetails />}
            />
            <Route
              path="league/:leagueId/club/:clubId/campaign/:campaignId/fund/:fundId"
              element={<FundCheckout />}
            />
            <Route
              path="league/:leagueId/club/:clubId/campaign/:campaignId/fund/:fundId/completed"
              element={<FundComplete />}
            />
            <Route path="info" element={<Info />} />
            <Route path="about" element={<About />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="policy/privacy" element={<Privacy />} />
            <Route path="policy/terms" element={<Terms />} />
            <Route path="policy/cookies" element={<Cookies />} />
            <Route path="contact" element={<Contact />} />
            <Route path="clubs" element={<Clubs />} />

            <Route path="form" element={<Auth />}>
              <Route index element={<FormNew />} />
              <Route
                path="league/:leagueId/club/:clubId/campaign/:campaignId/thanks"
                element={<FormThanks />}
              />
            </Route>

            <Route path="profile" element={<Auth />}>
              <Route path="" element={<Profile />}>
                <Route path="funds" element={<Funds />} />
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="settings" element={<Settings />} />
              </Route>
            </Route>

            <Route path="admin" element={<Admin />}>
              <Route index element={<AdminDashboard />} />
              <Route path="settings" element={<AdminSettings />} />
              <Route path="user" element={<AdminUser />} />
              <Route path="user/:userId" element={<AdminUserDetails />} />
              <Route path="league" element={<AdminLeague />} />
              <Route path="league/new" element={<AdminLeagueForm />} />
              <Route path="league/:leagueId" element={<AdminLeagueForm />} />
              <Route path="league/:leagueId/club" element={<AdminClub />} />
              <Route
                path="league/:leagueId/club/new"
                element={<AdminClubForm />}
              />
              <Route
                path="league/:leagueId/club/newAll"
                element={<AdminClubFormAll />}
              />
              <Route
                path="league/:leagueId/club/:clubId"
                element={<AdminClubForm />}
              />
              <Route path="campaign" element={<AdminCampaign />} />
              <Route
                path="league/:leagueId/club/:clubId/campaign/:campaignId"
                element={<AdminCampaignForm />}
              />
              <Route
                path="league/:leagueId/club/:clubId/campaign/:campaignId/fund"
                element={<AdminCampaignFund />}
              />
              <Route path="comment" element={<AdminComment />} />
              <Route path="fund" element={<AdminFund />} />
            </Route>
          </Route>
          <Route
            path="/embed/campaigns/:countryId/league/:leagueId/club/:clubId/limit/:limit"
            element={<DiscoverEmbed />}
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signin/forgot" element={<SignInForgot />} />
          <Route path="/signin/sent" element={<SignInSent />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup/complete" element={<Complete />} />
          <Route path="/status/:taskId" element={<Status />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
