import Markdown from "markdown-to-jsx";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import GeneralHeader from "../../components/general/header";
import Preloader from "../../components/general/preloader";
import ScrollUp from "../../components/general/scrollup";
import { useStore } from "../../store";

const Thanks = () => {
  const { clubId, leagueId } = useParams();
  const { auth, data } = useStore();
  const { t } = useTranslation();
  const name = auth.profile?.displayName;
  const { country } = data;
  const club = useMemo(
    () => (leagueId && clubId ? country?.club(leagueId, clubId) : undefined),
    [leagueId, clubId, country],
  );
  const image = useAsync(data.thanksImage, []);
  const headerImage = useAsync(data.headerImage, []);

  if (!club || !club.isLoaded || image.loading) return <Preloader />;

  return (
    <ScrollUp>
      <main id="main" className="site-main">
        <div className="campaign-thanks">
          <GeneralHeader image={headerImage.result} />
          <div className="container">
            <div className="main-content submit-thanks">
              <h1 className="title">{t("On the road to success")}</h1>
              <div>
                <img
                  src={image.result}
                  className="image-thanks"
                  alt={t("Thank you")}
                />
              </div>
              <div className="description">
                <Markdown className="markdown">
                  {t("md_after_action", { club: club.title, name })}
                </Markdown>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ScrollUp>
  );
};

export default observer(Thanks);
