import { Link } from "react-router-dom";

/* Looks like reach-router link component does not support opening in a new tab, event when using the target attribute.
 * This is needed for the embedded iframe component.
 * Suggestion is to use an anchor tag instead.
 * @source https://github.com/reach/router/issues/310
 */

interface LinkWrapperProps {
  newTab?: boolean;
  children: React.ReactNode;
  className?: string;
  to: string;
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { children, to, className, newTab, ...rest } = props;

  return (
    <>
      {newTab ? (
        <a
          className={className}
          href={to}
          target={"_blank"}
          rel="noreferrer"
          {...rest}
        >
          {children}
        </a>
      ) : (
        <Link className={className} to={to} {...rest}>
          {children}
        </Link>
      )}
    </>
  );
};

export default LinkWrapper;
