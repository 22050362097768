import { Data } from "../general/data";
import Document from "../general/document";

export interface TaskData extends Data {
  status: "pending" | "completed";
  code?: string;
}

class Task extends Document<TaskData> {
  get status() {
    return this.data.status;
  }

  get code() {
    return this.data.code;
  }

  get updatedAt() {
    return this.data.updatedAt.toDate();
  }
}

export default Task;
