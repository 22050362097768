import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import UserProfile from "./profile";
import { Profile } from "../../../store";

interface Props {
  profiles: Profile[];
}
const AdminUser = (props: Props) => {
  const { profiles } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("User")}</th>
            <th>{t("Contact")}</th>
            <th>{t("Status")}</th>
          </tr>
        </thead>
        <tbody>
          {profiles.map((profile) => (
            <UserProfile profile={profile} key={profile.id} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default observer(AdminUser);
