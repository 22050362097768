import { observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useStore } from "../../store";

const Auth = () => {
  const { auth } = useStore();
  const { isLoaded, isSignedIn, isRegComplete } = auth;
  const location = useLocation();
  if (!isLoaded) {
    return <div />;
  }

  if (!isSignedIn) {
    return <Navigate to={`/signin?from=${location.pathname}`} />;
  } else if (isSignedIn && !isRegComplete) {
    return <Navigate to={`/signup/complete?from=${location.pathname}`} />;
  } else {
    return (
      <div>
        <Outlet />
      </div>
    );
  }
};

export default observer(Auth);
