import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import {
  Link,
  useLocation,
  RouteProps,
  useNavigate,
  useParams,
} from "react-router-dom";
// import { useNavigate } from "react-router-dom-v5-compat";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { getQuery } from "../../helpers/tools";
import { useStore } from "../../store";
import Consent from "../general/consent";
import Input from "../general/input";

const SignIn = (props: RouteProps) => {
  const [email, setEmail] = useState({ value: "", isValid: true, message: "" });
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    message: "",
  });
  const [error, setError] = useState<string>();
  const { auth } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { from } = useParams();

  const message = () => {
    switch (from) {
      case "form":
        return t(
          "Before starting your new campaign you need to sign in or register.",
        );
      default:
        break;
    }
  };

  const updateEmail = (value: string) => {
    setEmail({ ...email, value });
  };

  const updatePassword = (value: string) => {
    setPassword({ ...password, value });
  };

  const isValid = () => {
    const emailValid = emailIsValid();
    const passwordValid = passwordIsValid();
    return emailValid && passwordValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(email.value)) {
      message = t("Enter your email address");
      isValid = false;
    } else if (!validator.isEmail(email.value)) {
      message = t("Invalid email address");
      isValid = false;
    }
    setEmail({ ...email, isValid, message });
    return isValid;
  };

  const passwordIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(password.value)) {
      isValid = false;
      message = t("Enter your password");
    }
    setPassword({ ...password, isValid, message });
    return isValid;
  };

  const signInEmail = async () => {
    if (isValid()) {
      try {
        const data = {
          email: email.value,
          password: password.value,
        };
        await auth.signInEmail(data);
        redirect();
      } catch (error) {
        setError(localizedError(error as Error, t));
      }
    }
  };

  const signInFacebook = async () => {
    try {
      await auth.signInFacebook();
      redirect();
    } catch (error) {
      setError(localizedError(error as Error, t));
    }
  };

  const signInGoogle = async () => {
    try {
      await auth.signInGoogle();
      redirect();
    } catch (error) {
      setError(localizedError(error as Error, t));
    }
  };

  const signInTwitter = async () => {
    try {
      await auth.signInTwitter();
      redirect();
    } catch (error) {
      setError(localizedError(error as Error, t));
    }
  };

  const redirect = () => {
    // const isNewUser = result.additionalUserInfo?.isNewUser;
    if (auth.isNewUser || !auth.email) {
      navigate("/signup/complete");
    } else {
      const { search } = location;
      const from = getQuery(search, "from") || "/";
      navigate(from);
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const renderInfo = () => {
    const { search } = location;
    const from = getQuery(search, "from") || "/";
    if (from === "/form")
      return (
        <div className="info">
          {t(
            "Register first to start a support action so we can communicate with you about the action. You can register with your email address or via your Facebook, Google and Twitter account.",
          )}
        </div>
      );
  };

  const cancel = () => {
    navigate(-1);
  };

  if (!auth.isLoaded) {
    return <div />;
  } else if (auth.isSignedIn) {
    redirect();
  }

  return (
    <div className="container">
      <div className="main-content content-login">
        <i onClick={cancel} className="close-icon fas fa-times"></i>
        <div className="form-login">
          <h1 className="h2">{t("Sign in to your account")}</h1>
          <div className="register">
            <Trans i18nKey="or_signup">
              Or{" "}
              <Link className="d-inline" to="/signup">
                {" "}
                create a new account
              </Link>
              .
            </Trans>
          </div>
          {message() && <p className="lead mt-3 mb-4">{message()}</p>}
          {renderInfo()}
          <form noValidate onSubmit={submit}>
            <div className="field">
              <Input
                invalid={!email.isValid}
                type="email"
                value={email.value}
                placeholder={t("email adress")}
                onChange={updateEmail}
              />
              <span className="invalid-feedback">{email.message}</span>
            </div>
            <div className="field">
              <Input
                invalid={!password.isValid}
                type="password"
                value={password.value}
                placeholder={t("Password")}
                onChange={updatePassword}
              />
              <Link to="/signin/forgot" className="forgot-pass mt-3">
                {t("Forgot your password?")}
              </Link>
              <span className="invalid-feedback">{password.message}</span>
            </div>
            <div className="inline clearfix">
              <Button
                type="submit"
                variant="primary"
                className="w-100"
                onClick={signInEmail}
              >
                {t("Sign in")}
              </Button>
              {/* <Button type="submit" variant="secondary" onClick={cancel}>
                {t("Cancel")}
              </Button> */}
            </div>
            {renderError()}
            <div className="my-5 position-relative">
              <div
                className="position-absolute d-flex align-items-center"
                style={{ top: 0, bottom: 0, left: 0, right: 0 }}
                aria-hidden="true"
              >
                <div className="w-100 border-top border-muted" />
              </div>
              <div className="position-relative d-flex justify-content-center">
                <span className="px-2 bg-white text-muted">
                  {t("Or continue with")}
                </span>
              </div>
            </div>
            <div className="social d-flex mt-1">
              <Button
                type="submit"
                className="facebook"
                onClick={signInFacebook}
              >
                <i className="fab fa-facebook" aria-hidden="true" />
              </Button>
              <Button type="submit" className="google" onClick={signInGoogle}>
                <i className="fab fa-google"></i>
              </Button>
              <Button type="submit" className="twitter" onClick={signInTwitter}>
                <i className="fa-brands fa-x-twitter" aria-hidden="true" />
              </Button>
            </div>
          </form>
          <Consent action={t("By signing in")} />
        </div>
      </div>
    </div>
  );
};

export default observer(SignIn);
