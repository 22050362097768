import classNames from "classnames";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { SyntheticEvent, useState, useEffect, useRef } from "react";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";
import validator from "validator";

import { localizedError } from "../../helpers/error";
import { useStore, CampaignAddData } from "../../store";
import DateInput from "../general/dateinput";
import Input from "../general/input";
import NumberInput from "../general/numberinput";
import Select from "../general/select";
import Textarea from "../general/textarea";

interface Value<T> {
  value: T;
  isValid: boolean;
  message: string;
}

interface Image extends File {
  preview: string;
}

interface TransformedOptions {
  value: string | undefined;
  label: string;
}

const MIN_DURATION = 10; // days
const MAX_DURATION = 365; // days

interface Props {}
const Form = (props: Props) => {
  const store = useStore();
  const asyncRef = useRef<SelectInstance | null>(null);
  const [club, setClub] = useState<Value<string>>({
    value: "",
    isValid: true,
    message: "",
  });
  const [title, setTitle] = useState<Value<string>>({
    value: "",
    isValid: true,
    message: "",
  });
  const [description, setDescription] = useState<Value<string>>({
    value: "",
    isValid: true,
    message: "",
  });
  const [goal, setGoal] = useState<Value<number | undefined>>({
    value: undefined,
    isValid: true,
    message: "",
  });
  const [endAt, setEndAt] = useState<Value<Date>>({
    value: moment()
      .add(MIN_DURATION + 1, "days")
      .toDate(),
    isValid: true,
    message: "",
  });
  const [images, setImages] = useState<Value<string[] | undefined>>({
    value: undefined,
    isValid: true,
    message: "",
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
  });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { country } = store.data;
  const leagues = country?.leagues.docs || [];
  const [leagueIndex, setLeagueIndex] = useState(0); // Set default to Amateurvoetbal
  const clubs = leagues[leagueIndex]?.clubs.docs;
  const currencyPrefix = country?.displayCurrencyPrefix;
  const [uploads, setUploads] = useState<Image[] | undefined>();
  const [selectedLeagueTitle, setSelectedLeagueTitle] = useState("");
  // const selectInputRef: any = useRef();

  // const transformedOptions = clubs?.map((option) => ({
  //   value: option.id,
  //   label: option.title,
  // }));

  useEffect(() => {
    if (leagues.length) {
      setSelectedLeagueTitle(leagues[0]?.title);
    }
  }, [leagues.length]);

  const loadOptions = (
    inputValue: string,
    callback: (options: TransformedOptions[]) => void,
  ) => {
    setTimeout(() => {
      callback(filterClubs(inputValue));
    }, 1000);
  };

  const filterClubs: any = (inputValue: string) => {
    return clubs
      ?.filter((i: any) =>
        i.title.toLowerCase().includes(inputValue.toLocaleLowerCase()),
      )
      .map((option) => ({
        value: option.id,
        label: option.title,
      }));
  };

  const customStyles = {
    container: () => ({ flex: "1 1 auto" }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: "0 0.25rem 0.25rem 0",
      borderColor: "#ededed",
    }),
  };

  useEffect(() => {
    setUploads(
      acceptedFiles?.map(
        (file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) }) as Image,
      ),
    );
  }, [acceptedFiles]);

  useEffect(() => {
    updateClub("");
    asyncRef.current?.clearValue();
  }, [leagueIndex]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const updateClub = (value: string) => {
    setClub({ ...club, value });
  };

  const customUpdateClub = (selectedOption: any) => {
    if (selectedOption) {
      const value: string = (selectedOption as { value: string; label: string })
        .value;
      setClub({ ...club, value });
    } else {
      setClub({
        value: "",
        isValid: true,
        message: "",
      });
    }
  };

  const updateTitle = (value: string) => {
    setTitle({ ...title, value });
  };

  const updateDescription = (value: string) => {
    setDescription({ ...description, value });
  };

  const updateGoal = (value?: number) => {
    setGoal({ ...goal, value: value ? value * 100 : undefined });
  };

  const updateEndAt = (value: Date) => {
    setEndAt({ ...endAt, value });
  };

  const updateLeague = (index: number, leagueTitle: string) => () => {
    setLeagueIndex(index);
    setSelectedLeagueTitle(leagueTitle);
    updateClub("");
    // selectInputRef.current.clearValue()
  };

  const isValid = () => {
    const clubValid = clubIsValid();
    const titleValid = titleIsValid();
    const descriptionValid = descriptionIsValid();
    const goalValid = goalIsValid();
    const imageValid = imagesIsValid();
    const endAtValid = endAtIsValid();
    return (
      clubValid &&
      titleValid &&
      descriptionValid &&
      goalValid &&
      imageValid &&
      endAtValid
    );
  };

  const clubIsValid = () => {
    let isValid = true;
    let message = "";
    if (validator.isEmpty(club.value)) {
      isValid = false;
      message = t("Choose a club");
    }
    setClub({ ...club, isValid, message });
    return isValid;
  };
  const titleIsValid = () => {
    let isValid = true;
    let message = "";

    if (validator.isEmpty(title.value)) {
      isValid = false;
      message = t("Enter a heading");
    }
    setTitle({ ...title, isValid, message });
    return isValid;
  };
  const descriptionIsValid = () => {
    let isValid = true;
    let message = "";

    if (validator.isEmpty(description.value)) {
      isValid = false;
      message = t("Enter a description");
    }
    setDescription({ ...description, isValid, message });
    return isValid;
  };

  const goalIsValid = () => {
    let isValid = true;
    let message = "";
    if (!goal.value || goal.value <= 0) {
      isValid = false;
      message = t("Enter a valid amount");
    }
    setGoal({ ...goal, isValid, message });
    return isValid;
  };

  const endAtIsValid = () => {
    let isValid = true;
    let message = "";

    if (!endAt.value) {
      isValid = false;
      message = t("Enter a valid end date");
    } else if (
      endAt.value.getTime() - Date.now() <
      MIN_DURATION * 24 * 60 * 60 * 1000
    ) {
      isValid = false;
      message = t("A support action must run for at least {{duration}} days", {
        duration: MIN_DURATION,
      });
    } else if (
      endAt.value.getTime() - Date.now() >
      MAX_DURATION * 24 * 60 * 60 * 1000
    ) {
      isValid = false;
      message = t(
        "A support action can run for not longer than {{duration}} days",
        {
          duration: MAX_DURATION,
        },
      );
    }
    setEndAt({ ...endAt, isValid, message });
    return isValid;
  };

  const imagesIsValid = () => {
    const isValid = true;
    // let message = "";
    // if (!image.value && !upload) {
    //   isValid = false;
    //   message = t("Choose a picture");
    // }
    // setImage({ ...image, isValid, message });
    return isValid;
  };

  const removeImages = () => {
    setUploads(undefined);
    setImages({ ...images, value: undefined });
  };

  const data = () =>
    ({
      title: {
        [store.i18n.language]: title.value,
      },
      description: {
        [store.i18n.language]: description.value,
      },
      goal: goal.value,
      images: images.value,
      userId: store.auth.userId,
      uploads,
      endAt: endAt.value,
    }) as CampaignAddData;

  const save = async () => {
    if (isValid()) {
      try {
        setLoading(true);
        if (!store.auth.userId) throw new Error("Not authorized");
        const leagueId = leagues[leagueIndex].id;
        if (!leagueId) throw new Error("League not found");
        const campaignClub = store.data.country?.club(leagueId, club.value);
        const campaign = await campaignClub?.addCampaign({
          ...data(),
          clubId: campaignClub?.id || "",
          leagueId,
        });
        navigate(
          `league/${leagueId}/club/${campaignClub?.id}/campaign/${campaign?.id}/thanks`,
        );

        setLoading(false);
      } catch (err) {
        runInAction(() => {
          setError(localizedError(err as Error, t));
          setLoading(false);
        });
      }
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const filterPassedDate = (date: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const renderClub = () => {
    return (
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline-secondary"
          title={leagues[leagueIndex]?.title || ""}
          className={classNames({ "is-invalid": !club.isValid })}
        >
          {leagues?.map((league, index) => (
            <Dropdown.Item
              key={league.id}
              onClick={updateLeague(index, league.title)}
            >
              {league.title}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {selectedLeagueTitle &&
          (selectedLeagueTitle === "Amateur Football" ||
          selectedLeagueTitle === "Amateurvoetbal" ? (
            clubs.length ? (
              <AsyncSelect
                ref={asyncRef}
                loadOptions={loadOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={t("Type in the name of your club")}
                noOptionsMessage={() => t("No clubs found")}
                styles={customStyles}
                onChange={customUpdateClub}
                defaultInputValue=""
              />
            ) : (
              <div className="fa-1x d-flex m-auto">
                <i className="fas fa-circle-notch fa-spin"></i>
              </div>
            )
          ) : (
            <Select
              onChange={updateClub}
              invalid={!club.isValid}
              value={club.value}
            >
              <option key="empty" value="">
                {t("Select a club")}
              </option>
              {clubs?.map((aClub) => (
                <option key={aClub.id} value={aClub.id}>
                  {aClub.title}
                </option>
              ))}
            </Select>
          ))}
      </InputGroup>
    );
  };

  const renderUpload = () => {
    const logoClass = classNames("file-upload upload-bg", {
      "is-invalid": !images.isValid,
    });
    const previews = uploads?.length
      ? uploads.map((upload) => upload.preview)
      : images?.value;
    if (previews?.length) {
      return (
        <div className={logoClass}>
          <div className="upload-preview">
            {previews.map((preview, index) => (
              <img key={index} src={preview} alt={t("Example")} />
            ))}
            <Button variant="danger" onClick={removeImages}>
              {t("Delete")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={logoClass}>
          <div {...getRootProps({ className: "dropzone choicefile1" })}>
            <input {...getInputProps()} />
            <i className="fas fa-cloud-upload-alt" aria-hidden="true" />
            <div>
              <h4>{t("Upload")}</h4>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="campaign-form form-update">
      <div className="container">
        <form noValidate onSubmit={submit}>
          <div className="form-group clearfix">
            <label htmlFor="clocation">{t("Choose your club")} *</label>
            <div>
              {renderClub()}
              <span className="invalid-feedback">{club.message}</span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="title">{t("Heading support action")} *</label>
            <Input
              invalid={!title.isValid}
              type="text"
              value={title.value}
              placeholder={t("What is the heading of your support action")}
              onChange={updateTitle}
            />
            <span className="invalid-feedback">{title.message}</span>
          </div>
          <div className="form-group">
            <label htmlFor="description">{t("Description")} *</label>
            <Textarea
              invalid={!description.isValid}
              value={description.value}
              cols={30}
              rows={4}
              placeholder={t("Define your idea")}
              onChange={updateDescription}
            />
            <span className="invalid-feedback">{description.message}</span>
          </div>
          <div className="form-group">
            <label htmlFor="goal">{t("Target amount donations")}</label>
            <NumberInput
              invalid={!goal.isValid}
              value={goal?.value && goal.value / 100}
              prepend={currencyPrefix}
              placeholder={t("Estimation of the target amount")}
              onChange={updateGoal}
            />
            <span className="invalid-feedback">{goal.message}</span>
          </div>
          <div className="form-group">
            <label htmlFor="endAt">{t("Target end date")}</label>
            <DateInput
              invalid={!endAt.isValid}
              selected={endAt.value}
              placeholderText={t("Estimation of the end date")}
              onChange={updateEndAt}
              filterDate={filterPassedDate}
            />
            <span className="invalid-feedback">{endAt.message}</span>
          </div>
          <div className="form-group">
            <label htmlFor="uploadfile">
              {t("Upload one or more pictures")}
            </label>
            {renderUpload()}
            <small className="form-text text-muted">
              {t("png, jpg, gif with max. size of 5MB")}
            </small>
            <span className="invalid-feedback">{images.message}</span>
          </div>
          <Button
            type="submit"
            disabled={loading}
            variant="primary"
            onClick={save}
          >
            {loading ? t("Upload...") : t("Save support action")}
          </Button>
          {renderError()}
          <p className="info-disclaimer">
            <Trans>
              After submitting your action, Clubmeister will review and moderate
              your support action and inform you when your support action is
              granted.
            </Trans>
          </p>
        </form>
      </div>
    </div>
  );
};

export default observer(Form);
