import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import Discover, { Filter } from "../../components/explore/discover";
import Preloader from "../../components/general/preloader";
import { Country, useStore } from "../../store";

const DiscoverEmbed = () => {
  const { countryId, leagueId, clubId, limit } = useParams();
  const { data } = useStore();
  const [country, setCountry] = useState<Country>();

  useEffect(() => {
    if (countryId) {
      data.countryId = countryId;
      setCountry(data.country);
    }
  }, [countryId, data]);

  const club = useMemo(
    () => (leagueId && clubId ? country?.club(leagueId, clubId) : undefined),
    [leagueId, clubId, country],
  );

  const filter = {
    club,
  } as Filter;

  if (!country?.isLoaded && !country?.title) return <Preloader />;
  if (!club || !club.isLoaded) return <Preloader />;
  return (
    <Discover
      filter={filter}
      limit={limit ? parseInt(limit, 10) : undefined}
      isEmbed={true}
    />
  );
};

export default observer(DiscoverEmbed);
