import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useStore } from "../../store";

const Menu = () => {
  const { auth } = useStore();
  const { profile, isAdmin, isSignedIn } = auth;
  const location = useLocation();
  const { t } = useTranslation();

  const renderAccount = () => {
    if (isSignedIn && profile) {
      return (
        <nav className="main-menu">
          <ul>
            <li>
              <Link to="#">
                <i className="far fa-user" aria-hidden="true" />
              </Link>
              <ul className="sub-menu">
                <li>
                  <div className="user">
                    <span>{profile.displayName}</span>
                    <span className="email">{profile.email}</span>
                  </div>
                </li>
                {isAdmin && (
                  <li>
                    <Link to="/">{t("Home")}</Link>
                  </li>
                )}
                <li>
                  <Link to="#" onClick={auth.signOut}>
                    {t("Sign out")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      );
    }
  };

  const renderSignIn = () => {
    if (!isSignedIn) {
      const path = location.pathname;
      const to = "/signin" + (path ? `?from=${path}` : "");
      return (
        <div className="login login-button">
          <Link to={to} className="btn-primary">
            <i className="far fa-user" aria-hidden="true" />
            <span>{t("Login")}</span>
          </Link>
        </div>
      );
    }
  };

  return (
    <header id="header" className="site-header admin-menu">
      <div className="content-header">
        <div className="container">
          <div className="left-header">
            <nav className="main-menu">
              <button className="c-hamburger c-hamburger--htx">
                <span />
              </button>
              <ul>
                <li>
                  <Link to="admin">{t("Home")}</Link>
                </li>
                <li>
                  <Link to="admin/settings">{t("Settings")}</Link>
                </li>
                <li>
                  <Link to="admin/user">{t("Users")}</Link>
                </li>
                <li>
                  <Link to="admin/league">{t("Clubs")}</Link>
                </li>
                <li>
                  <Link to="admin/campaign">{t("Actions")}</Link>
                </li>
                <li>
                  <Link to="admin/fund">{t("Funds")}</Link>
                </li>
                <li>
                  <Link to="admin/comment">{t("Comments")}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right-header">
            {renderAccount()}
            {/* {renderSearch()} */}
            {renderSignIn()}
          </div>
        </div>
      </div>
    </header>
  );
};

export default observer(Menu);
