import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NotificationData, useStore } from "../../store";

interface Setting {
  id: string;
  title: string;
  description: string;
}

const Notifications = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const { profile } = auth;
  const [notifications, setNotifications] = useState<NotificationData>(
    profile?.notificationPreferences
      ? profile?.notificationPreferences
      : {
          donations: {
            dateChanged: new Date(),
            accepted: true,
          },
          marketing: {
            dateChanged: new Date(),
            accepted: false,
          },
        },
  );

  const settings = [
    {
      id: "donations",
      title: t("Donations"),
      description: t(
        "Receive a message if the club cashes in your donation or if you get your money back",
      ),
    },
    {
      id: "marketing",
      title: t("Newsletter"),
      description: t("Receive news about the platform"),
    },
  ];

  const updateFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const value = e.target.checked;
    setNotifications({
      ...notifications,
      [id]: {
        accepted: value,
        dateChanged: new Date(),
      },
    });
  };

  useEffect(() => {
    (async function () {
      try {
        await profile?.update({ notifications });
      } catch (error) {
        console.debug(error as Error, t);
      }
    })();
  }, [notifications, profile, t]);

  const renderSetting = (setting: Setting) => {
    const { id, title, description } = setting;
    return (
      <tr key={id}>
        <td>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              onChange={updateFields}
              defaultChecked={notifications[id]?.accepted}
              id={id}
              type="checkbox"
              label={
                <>
                  <span className="title">{title}</span>
                  <br />
                  <span className="description text-muted">{description}</span>
                </>
              }
            />
          </Form.Group>
        </td>
      </tr>
    );
  };
  return (
    <div className="settings-table notification-preferences">
      <Table>
        <tbody>{settings.map(renderSetting)}</tbody>
      </Table>
    </div>
  );
};

export default Notifications;
