import nl from "date-fns/locale/nl";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { observable, runInAction } from "mobx";
import moment from "moment";
import numeral from "numeral";
import { registerLocale } from "react-datepicker";
import { initReactI18next } from "react-i18next";
import "moment/locale/nl";
import "numeral/locales/nl-nl";
import "numeral/locales/en-gb";

import Store from "..";

class I18nStore {
  _locale = observable.box("nl-nl");
  readonly store: Store;
  readonly locales: string[];
  constructor(store: Store) {
    this.store = store;
    const resources = {
      "nl-NL": {
        translation: require("../../assets/locales/nl/translation.json"),
      },
      "en-GB": {
        translation: require("../../assets/locales/en/translation.json"),
      },
    };
    registerLocale("nl-NL", nl);

    this.locales = Object.keys(resources);

    i18n.on("languageChanged", (lng) => {
      runInAction(() => {
        this._locale.set(lng);
        numeral.locale(lng);
        moment.locale(lng);
      });
    });

    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        debug: false, //!process.env.NODE_ENV || process.env.NODE_ENV === "development",
        nsSeparator: false,
        keySeparator: false,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
        react: {
          transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
        },
        supportedLngs: this.locales,
      });
  }

  get locale() {
    return this._locale.get();
  }

  get language() {
    return this.locale.split("-")[0];
  }

  get languages() {
    return this.locales.map((locale) => locale.split("-")[0]);
  }

  async changeLocale(locale: string) {
    const language = locale.split("-")[0];
    await this.store.auth.profile?.update({ language });
    return i18n.changeLanguage(locale);
  }
}

export default I18nStore;
