import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import ContactForm from "../components/contact/form";
import ScrollUp from "../components/general/scrollup";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("Drop us a line")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </main>
    </ScrollUp>
  );
};

export default Contact;
