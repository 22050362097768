import firebase from "firebase/compat/app";
import { initFirestorter } from "firestorter";
import { createContext, useContext } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

import AuthStore from "./auth";
import DataStore from "./data";
import I18nStore from "./i18n";
import Config from "../config.json";

const fb = () => {
  try {
    const firebaseConfig = Config.firebase;
    const app = firebase.initializeApp(firebaseConfig); // Initialize firebase app

    // Configure emulators.
    process.env.REACT_APP_FUNCTIONS_EMULATOR &&
      firebase.functions().useEmulator("localhost", 5001);
    process.env.REACT_APP_FIRESTORE_EMULATOR &&
      firebase.firestore().useEmulator("localhost", 8080);

    // Initialize `firestorter`
    initFirestorter({ firebase });
    return app;
  } catch (error) {
    console.error(error);
  }
};

const app = fb();

export * from "./auth";
export * from "./data";

export default class Store {
  i18n = new I18nStore(this);
  auth = new AuthStore(this);
  data = new DataStore(this);
  app = app;
}

const storeContext = createContext(new Store());
export const useStore = () => {
  const store = useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
