import Markdown from "markdown-to-jsx";
import React from "react";
import { useAsync } from "react-async-hook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import GeneralHeader from "../components/general/header";
import ScrollUp from "../components/general/scrollup";
import { useStore } from "../store";

const Info = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const image = useAsync(data.headerImage, []);
  return (
    <ScrollUp>
      <Helmet>
        <title>
          {`Clubmeister`} - {t("How it works?")}
        </title>
      </Helmet>
      <main id="main" className="site-main">
        <div className="campaign-detail">
          <GeneralHeader image={image.result} />
          <div className="container">
            <div className="main-content info">
              <h1 className="title">{t("How it works?")}</h1>
              <div className="description mt-5">
                <Markdown className="markdown">{t("md_info_text")}</Markdown>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ScrollUp>
  );
};

export default Info;
