import { observer } from "mobx-react-lite";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  Badge,
  Button,
  Alert,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Campaign, Fund, Profile, useStore } from "../../store";
import Select from "../general/select";

interface Props {
  funds: Fund[];
  profiles: Profile[];
  campaigns: Campaign[];
  fundPage: boolean;
}
const AdminFund = (props: Props) => {
  const { funds, profiles, campaigns, fundPage } = props;
  const { data } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<false | string>(false);
  const [error, setError] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [refund, setRefund] = useState<Fund | undefined>();
  const { country } = data;
  const clubs = country?.leagues.docs.flatMap(
    (league: any) => league.clubs.docs,
  );
  const [club, setClub] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [campaign, setCampaign] = useState<string>("");
  const [filteredFunds, setFilteredFunds] = useState(funds);
  const [showConfirm, setShowConfirm] = useState(false);
  const [refundCampaign, setRefundCampaign] = useState<any>();
  const [filterEnable, setFilterEnable] = useState(false);
  const [campaignFilterEnable, setCampaignFilterEnable] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [isShowRefundAll, setShowRefundAll] = useState(false);
  const statuses = [
    "Open",
    "Paid",
    "Canceled",
    "Expired",
    "Verified",
    "Failed",
  ];

  const createRefund = (fund: any) => async () => {
    setError("");
    setLoading(fund.id || false);
    setConfirmModal(false);
    try {
      await data.admin.createRefund(fund);
    } catch (error) {
      const { message } = error as Error;
      setError(message);
    }
    setLoading(false);
  };

  const renderConfirmModal = () => (
    <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans>Refund donation</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans>
          Are you sure you want to refund donation? This action cannot be
          reversed.
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => setConfirmModal(false)}>
          <Trans>Cancel</Trans>
        </Button>
        <Button variant="danger" onClick={createRefund(refund)}>
          <Trans>Refund</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderFund = (fund: Fund) => {
    const { campaign, amount, refundStatus } = fund;
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 6);
    if (
      fund.id &&
      campaign &&
      campaign?.user?.id &&
      campaign?.user?.displayName &&
      fund.createdAt &&
      campaign?.club?.logo &&
      campaign?.isPublished &&
      new Date(campaign?.endAt) > threeMonthsAgo
    ) {
      const currencyPrefix =
        campaign?.club?.league?.country?.displayCurrencyPrefix;

      const refund =
        fund.isLoaded &&
        !fund.isOpen &&
        (refundStatus === "succeeded" ? (
          <Badge variant="primary" className="float-right">
            <Trans>Refunded</Trans>
          </Badge>
        ) : refundStatus === "pending" ? (
          <Badge variant="warning" className="float-right">
            <Trans>Refund pending</Trans>
          </Badge>
        ) : refundStatus === "failed" ? (
          <Badge variant="danger" className="float-right">
            <Trans>Refund failed</Trans>
          </Badge>
        ) : (
          <Button
            size="sm"
            onClick={() => {
              setRefund(fund);
              setConfirmModal(true);
            }}
            className="float-right"
            disabled={loading === fund.id}
          >
            {loading === fund.id ? (
              <Trans>Loading...</Trans>
            ) : (
              <Trans>Refund</Trans>
            )}
          </Button>
        ));
      return (
        <tr className="campaign" key={fund.id}>
          <td>{campaign && renderCampaign(campaign)}</td>
          <td>
            {currencyPrefix} {numeral(amount / 100).format("0,0.00")}
            <Badge
              className="ml-2"
              variant={
                fund.status === "paid"
                  ? "success"
                  : fund.status === "canceled"
                    ? "danger"
                    : "secondary"
              }
            >
              {fund.status}
            </Badge>
          </td>
          <td>
            {fund.createdAt ? new Date(fund.createdAt).toLocaleString() : ""}
            {refund}
          </td>
        </tr>
      );
    }
  };

  const renderCampaign = (campaign: Campaign) => {
    const path = campaign.link.replace(
      `/${data.country?.id}`,
      `/${data.country?.id}/admin`,
    );
    const { club, title, user, createdAt } = campaign;
    return (
      <Row>
        <Col sm="auto">
          <img className="logo" src={club?.logo} alt={t("Club")} />
        </Col>
        <Col>
          <div>
            <Link
              to={`/${data.country?.id}/admin/user/${user?.id}`}
              className="no-wrap"
            >
              <cite className="campaign-user">
                {user?.displayName || t("Anonymous")}
              </cite>
            </Link>
            <span className="campaign-date">
              {createdAt ? moment(createdAt).fromNow() : ""}
            </span>
          </div>
          <Link to={path}>{title}</Link>
        </Col>
      </Row>
    );
  };

  const renderEmpty = () => {
    return <div className="data-empty">{t("No funds found")}</div>;
  };

  const updateStatus = (value: string) => {
    setStatus(value);
  };

  const updateClub = (value: string) => {
    setClub(value);
  };

  const updateUser = (value: string) => {
    setUser(value);
  };

  const updateCampaign = (value: any) => {
    setRefundCampaign(campaigns.find((campaign) => campaign.id === value));
    setCampaign(value);
    if (!value) {
      setCampaignFilterEnable(false);
    }
  };

  const renderStatus = () => {
    return (
      <InputGroup>
        <Select onChange={updateStatus} value={status}>
          <option key="empty" value="">
            {t("Select a status")}
          </option>
          {statuses?.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </Select>
      </InputGroup>
    );
  };

  const renderClub = () => {
    return (
      <InputGroup>
        <Select onChange={updateClub} value={club}>
          <option key="empty" value="">
            {t("Select a club")}
          </option>
          {clubs?.map((aClub) => {
            return (
              <option key={aClub.id} value={aClub.id}>
                {aClub.title}
              </option>
            );
          })}
        </Select>
      </InputGroup>
    );
  };

  const renderUsers = () => {
    return (
      <InputGroup>
        <Select onChange={updateUser} value={user}>
          <option key="empty" value="">
            {t("Select a user")}
          </option>
          {profiles.map((profile) => (
            <option value={profile.id} key={profile.id}>
              {profile.displayName}
            </option>
          ))}
        </Select>
      </InputGroup>
    );
  };

  const renderCampaigns = () => {
    return (
      <InputGroup>
        <Select onChange={updateCampaign} value={campaign}>
          <option key="empty" value="">
            {t("Select a campaign")}
          </option>
          {campaigns.map((campaign: Campaign) => (
            <option value={campaign.id} key={campaign.id}>
              {campaign.title}
            </option>
          ))}
        </Select>
      </InputGroup>
    );
  };

  const filterDonations = () => {
    setCampaignFilterEnable(!!campaign);
    setFilterEnable(true);
    let filterFunds = filteredFunds;
    filterFunds = funds.filter((fund) => {
      const userFilter = user ? fund?.campaign?.user?.id === user : true;
      const clubFilter = club ? fund?.campaign?.club?.id === club : true;
      const campaignFilter = campaign ? fund?.campaign?.id === campaign : true;
      const statusFilter =
        status && fund?.status
          ? fund?.status.toLocaleLowerCase() === status.toLocaleLowerCase()
          : true;
      return userFilter && clubFilter && campaignFilter && statusFilter;
    });
    const paidAmount = filterFunds.reduce(
      (total: any, current: any) =>
        total +
        (current?.status.toLocaleLowerCase() === "paid"
          ? current?.amount / 100
          : 0),
      0,
    );
    const showRefundAll = filterFunds.some(
      (fund: any) => fund.isLoaded && !fund.isOpen && !fund.refundStatus,
    );
    setShowRefundAll(showRefundAll);
    setTotalPaidAmount(paidAmount);
    setFilteredFunds(filterFunds);
  };

  const cancelFilter = () => {
    setCampaignFilterEnable(false);
    setFilterEnable(false);
    setFilteredFunds(funds);
    setUser("");
    setClub("");
    setCampaign("");
    setStatus("");
  };

  const filterData = () => {
    return (
      <Form.Group controlId="filterData">
        <Row>
          <Col md={2}>
            <Form.Label>
              <Trans>Status</Trans>
            </Form.Label>
            {renderStatus()}
          </Col>
          <Col md={2}>
            <Form.Label>
              <Trans>Clubs</Trans>
            </Form.Label>
            {renderClub()}
          </Col>
          <Col md={2}>
            <Form.Label>
              <Trans>Campaigns</Trans>
            </Form.Label>
            {renderCampaigns()}
          </Col>
          <Col md={2}>
            <Form.Label>
              <Trans>Usernames</Trans>
            </Form.Label>
            {renderUsers()}
          </Col>
          <Col md={2}>
            <Button
              variant="primary"
              size="sm"
              className="mt-4"
              onClick={filterDonations}
            >
              <Trans>Filter</Trans>
            </Button>
            <Button
              variant="light"
              size="sm"
              className="mt-4 ml-3"
              onClick={() => cancelFilter()}
            >
              <Trans>Cancel</Trans>
            </Button>
          </Col>
          {campaignFilterEnable &&
            campaign &&
            filteredFunds?.length > 0 &&
            isShowRefundAll && (
              <Col>
                <Button
                  onClick={() => setShowConfirm(true)}
                  className="mt-4"
                  size="sm"
                  variant="primary"
                  disabled={!!loading}
                >
                  {loading ? t("Loading...") : t("Refund all")}
                </Button>
              </Col>
            )}
        </Row>
        {filterEnable && filteredFunds?.length > 0 && (
          <>
            <Row>
              <Col md={4} className="mt-3">
                <h6>
                  <Trans>Total number of donations</Trans>:{" "}
                  {filteredFunds.length}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mt-2">
                <h6>
                  <Trans>Total value of paid amount</Trans>: {totalPaidAmount}
                </h6>
              </Col>
            </Row>
          </>
        )}
      </Form.Group>
    );
  };

  const campaignRefund = async () => {
    setError("");
    setLoading("*");
    setShowConfirm(false);
    try {
      refundCampaign && (await data.admin.refundCampaign(refundCampaign));
    } catch (error) {
      const { message } = error as Error;
      setError(message);
    }
    setLoading(false);
  };

  return (
    <div>
      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}
      {!fundPage && filterData()}
      {renderConfirmModal()}
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Refund all donations</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans>
            Are you sure you want to refund all donations? This action cannot be
            reversed.
          </Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => setShowConfirm(false)}>
            <Trans>Cancel</Trans>
          </Button>
          <Button variant="danger" onClick={campaignRefund}>
            <Trans>Refund all</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
      {filteredFunds.length === 0 ? (
        renderEmpty()
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("Campaign")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Date")}</th>
            </tr>
          </thead>
          <tbody>{filteredFunds.map(renderFund)}</tbody>
        </Table>
      )}
    </div>
  );
};

export default observer(AdminFund);
