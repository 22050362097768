import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import AdminUserDetails from "../../../components/admin/user/details";
import Preloader from "../../../components/general/preloader";
import { useStore } from "../../../store";

const Details = () => {
  const { userId } = useParams();
  const { data } = useStore();

  const profile = useMemo(
    () => (userId ? data.admin.profile(userId) : undefined),
    [userId, data.admin],
  );

  if (!profile?.isLoaded) return <Preloader />;

  return (
    <div className="admin">
      <AdminUserDetails profile={profile} />
    </div>
  );
};

export default observer(Details);
